// LinktreePage.js
import React, { useState, useEffect } from 'react';
import styles from './Join.module.css'; // Import the module.css file

const LinktreePage = () => {
  const [returnMessage, setReturnMessage] = useState('');

  useEffect(() => {
      const newQueryParams = new URLSearchParams(window.location.search);
      const r = newQueryParams.get('returnMessage');
      if (r) {
        setReturnMessage(r)
      }
  }, []);

  return (
    <div className={styles.linktreePage}>
        <div className={styles.dashboardContainer}>
          <div className={styles.mainHeading}>{returnMessage}</div>
          <div className={styles.subHeadingTransparent}>
            Daftarkan kedaimu sekarang dan mulai gunakan semua fitur unggulan kami.
          </div>
        </div>
    </div>
  );
};

export default LinktreePage;
