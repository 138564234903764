import React, { useRef, useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import { useParams } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import {
  getTransaction,
  confirmTransaction,
  declineTransaction,
} from "../helpers/transactionHelpers";
import { getTables } from "../helpers/tableHelper";
import TableCanvas from "../components/TableCanvas";
import { useSearchParams } from "react-router-dom";

export default function Transactions({ propsShopId, sendParam, deviceType, handleMoveToTransaction, shopImg }) {
  const { shopId, tableId } = useParams();
  if (sendParam) sendParam({ shopId, tableId });

  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [transaction, setTransaction] = useState(null);
  const [notAcceptedItems, setNotAcceptedItems] = useState([]);
  const noteRef = useRef(null);

  const [transactionRefreshKey, setTransactionRefreshKey] = useState(0);

  useEffect(() => {
    const transactionId = searchParams.get("transactionId") || "";

    const fetchData = async () => {
      try {
        const fetchedTransaction = await getTransaction(transactionId);
        setTransaction(fetchedTransaction);
        console.log(transaction);
      } catch (error) {
        console.error("Error fetching transaction:", error);
      }
    };
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedTables = await getTables(shopId || propsShopId);
        setTables(fetchedTables);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };

    fetchData();
  }, [shopId || propsShopId]);

  const calculateTotalPrice = (detailedTransactions) => {
    return detailedTransactions.reduce((total, dt) => {
      return total + dt.qty * (dt.promoPrice ? dt.promoPrice : dt.price);
    }, 0);
  };

  const handleConfirm = async (transactionId) => {
    if (isPaymentLoading) return;
    setIsPaymentLoading(true);
    try {
      const c = await confirmTransaction(transactionId, notAcceptedItems);
      if (c) {
        console.log(c)
        setTransaction(c);
        setTransactionRefreshKey(transactionRefreshKey+1);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const handleDecline = async (transactionId) => {
    if (isPaymentLoading) return;
    setIsPaymentLoading(true);
    try {
      const c = await declineTransaction(transactionId);
      if (c) {
        console.log(c)
        setTransaction({ ...transaction, confirmed: c.confirmed });
        setTransactionRefreshKey(transactionRefreshKey+1);
      }
      // if (c) {
      //   // Update the confirmed status locally
      //   setTransactions((prevTransactions) =>
      //     prevTransactions.map((transaction) =>
      //       transaction.transactionId === transactionId
      //         ? { ...transaction, confirmed: -1 } // Set to confirmed
      //         : transaction
      //     )
      //   );
      // }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const autoResizeTextArea = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set new height
    }
  };

  useEffect(() => {
    if (noteRef.current) {
      autoResizeTextArea(noteRef.current);
    }
  }, [transaction?.notes]);

  return (
    <div key={transactionRefreshKey} className={styles.Transaction}>

      <div className={styles.TransactionListContainer}>
        {transaction && (
          <div
            key={transaction.transactionId}
            className={styles.RoundedRectangle}
            onClick={() =>
              setSelectedTable(transaction.Table || { tableId: 0 })
            }
          >

            <div className={styles['receipt-header']}>
              <div className={styles['receipt-info']}>
                <h3>{transaction.payment_type == 'cash' ? 'Tunai' : transaction.payment_type == 'cashless' ? 'Non tunai' : transaction.payment_type == 'paylater' ? 'Open bill' :'Close bill' }</h3>
                <p>Transaction ID: {transaction.transactionId}</p>
                {
                  transaction.payment_type == 'paylater/cash' ?
                  <p>Cash</p>
                  :
                  (transaction.payment_type == 'paylater/cashless' &&
                  <p>Non tunai</p>
                  )
                }
              </div>
            </div>

            <div className={styles['dotted-line']}>
              <div className={styles['circle-left']} onClick={() => handleMoveToTransaction('previous', transaction.transactionId)}>
                <div className={styles["inner-circle"]}>
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    style={{ transform: 'Rotate(-90deg)', width: '100%', height: '100%' }} // Ensure SVG fits the div
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M 0.198 14.001 C -0.192 14.392 -0.088 11.275 0.1 11.088 L 7.293 4.293 C 7.684 3.902 8.316 3.902 8.707 4.293 L 15.833 11.258 C 16.021 11.445 16.203 14.644 15.812 14.253 L 8 6.414 L 0.198 14.001 Z" fill="#dbdbdb"></path>
                    </g>
                  </svg>
                </div>
                <div className={styles.circle}>1</div>
              </div>
              <div className={styles['line']} ></div>
              <div className={styles['circle-right']} onClick={() => handleMoveToTransaction('next', transaction.transactionId)}>
                <div className={styles["inner-circle"]}>
                  <span className="inner-text"><svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    style={{ transform: 'Rotate(90deg)', width: '100%', height: '100%' }} // Ensure SVG fits the div
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M 0.198 14.001 C -0.192 14.392 -0.088 11.275 0.1 11.088 L 7.293 4.293 C 7.684 3.902 8.316 3.902 8.707 4.293 L 15.833 11.258 C 16.021 11.445 16.203 14.644 15.812 14.253 L 8 6.414 L 0.198 14.001 Z" fill="#dbdbdb"></path>
                    </g>
                  </svg>
                  </span>

                </div>
              </div>
            </div>
            <div className={styles.RibbonBanner}></div>
            <div>
              {transaction.DetailedTransactions.map((detail) => (
                <div className={styles['itemContainer']} key={detail.detailedTransactionId}>
                  <div className={styles['plusNegative']} style={{ visibility: transaction.confirmed >= 0 && detail.acceptedStatus == 0 ? 'visible' : 'hidden', margin: transaction.confirmed >= 0 &&  detail.acceptedStatus == 0 ? '4px 10px 0px 10px' : '4px 0px 0px 0px' }}
                    onClick={() =>
                      setNotAcceptedItems(prevState =>
                        prevState.includes(detail.detailedTransactionId)
                          ? prevState.filter(item => item !== detail.detailedTransactionId)
                          : [...prevState, detail.detailedTransactionId]
                      )
                    }
                  >
                    {!notAcceptedItems.includes(detail.detailedTransactionId) ?
                      <svg
                        className={styles['plusNegative2']}
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                          fillRule="nonzero"
                        />
                      </svg>
                      :
                      <svg className={styles['plusNegative2']}
                        viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><path d="M25 38c-5.1 0-9.7-3-11.8-7.6l1.8-.8c1.8 3.9 5.7 6.4 10 6.4 6.1 0 11-4.9 11-11s-4.9-11-11-11c-4.6 0-8.5 2.8-10.1 7.3l-1.9-.7c1.9-5.2 6.6-8.6 12-8.6 7.2 0 13 5.8 13 13s-5.8 13-13 13z" /><path d="M20 22h-8v-8h2v6h6z" /></svg>
                    }
                  </div>

                  <span style={{
                    display: 'inline-block',
                    maxWidth: '100px', // Adjust the max-width as needed
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  {detail.Item.name}</span> - {notAcceptedItems.includes(detail.detailedTransactionId) || detail.qty < 1 ? 'tidak tersedia' : `${detail.qty} x Rp
                  ${detail.promoPrice ? detail.promoPrice : detail.price}`}

                </div>
              ))}
            </div>
            <h2 className={styles["Transactions-detail"]}>
              {transaction.serving_type === "pickup"
                ? "Ambil sendiri"
                : `Diantar ke ${transaction.Table ? transaction.Table.tableNo : "N/A"
                }`}
            </h2>
            {transaction.notes != "" && (
              <>
                <div className={styles.NoteContainer}>
                  <span>Note :</span>
                  <span></span>
                </div>

                <div className={styles.NoteContainer}>
                  <textarea
                    className={styles.NoteInput}
                    value={transaction.notes}
                    ref={noteRef}
                    disabled
                  />
                </div>
              </>
            )}
            <div className={styles.TotalContainer}>
              <span>Total:</span>
              <span>
                Rp {calculateTotalPrice(transaction.DetailedTransactions)}
              </span>
            </div>
            <div className={styles.TotalContainer}>
              <button
                className={styles.PayButton}
                onClick={() => handleConfirm(transaction.transactionId)}
                disabled={
                  transaction.confirmed === -1 ||
                  transaction.confirmed === 3 ||
                  isPaymentLoading
                } // Disable button if confirmed (1) or declined (-1) or
              >
                {isPaymentLoading ? (
                  <ColorRing height="50" width="50" color="white" />
                ) : transaction.confirmed === 1 ? (
                  "Konfirmasi telah bayar" // Display "Confirm has paid" if the transaction is confirmed (1)
                ) : transaction.confirmed === -1 ? (
                  "Ditolak" // Display "Declined" if the transaction is declined (-1)
                ) : transaction.confirmed === -2 ? (
                  "Dibatalkan" // Display "Declined" if the transaction is declined (-1)
                ) : transaction.confirmed === 2 ? (
                  "Konfirmasi pesanan siap" // Display "Item ready" if the transaction is ready (2)
                ) : transaction.confirmed === 3 ? (
                  "Transaction success" // Display "Item ready" if the transaction is ready (2)
                ) : (
                  "Konfirmasi ketersediaan" // Display "Confirm availability" if the transaction is not confirmed (0)
                )}
              </button>
            </div>
            {transaction.confirmed >= 0 && transaction.confirmed < 2 && (
              <h5
                className={styles.DeclineButton}
                onClick={() => handleDecline(transaction.transactionId)}
              >
                batalkan
              </h5>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
