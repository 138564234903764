import React, { useState } from 'react';
import { updateUser } from '../helpers/userHelpers';
import styles from '../pages/Join.module.css'; // Import the module.css file

const AccountUpdatePage = ({ user, showEmail, onSubmit }) => {
    const [formData, setFormData] = useState({
        username: user.username || '',
        email: user.email || '',
        password: '&#9679;&#9679;&#9679;&#9679;&#9679;',
    });
    
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // New state for success messages

    
    
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(false);
      const [wasInputtingPassword, setWasInputtingPassword] = useState(false);
      const [inputtingPassword, setInputtingPassword] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate password length on the frontend
        if (formData.password && formData.password.length < 6) {
            setErrorMessage('Password is too short');
            return;
        }

        try {
            const response = await updateUser(formData); // Call the updated helper function

            // If there's a success message, display it
            if (response.message) {
                setSuccessMessage(response.message); // Set the success message from the response
                setErrorMessage(''); // Clear any previous error messages
            }
        } catch (error) {
            // Handle the error by displaying the error message
            setErrorMessage(error.message || 'Failed to update user. Please try again.');
            setSuccessMessage(''); // Clear success message if there's an error
        }
    };

    return (
        
    <div className={styles.linktreePage}>
    <div className={styles.dashboardContainer}>
      <div className={styles.mainHeading}>Ubah profil</div>
      <div className={styles.subHeadingTransparent}>
        Daftarkan kedaimu sekarang dan mulai gunakan semua fitur unggulan kami.
      </div>

        <div className={styles.LoginForm}>
        <div className={`${styles.FormUsername} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
          <label htmlFor="username" className={styles.usernameLabel}>--------------------------------------------</label>
          
          <input
            name="username"
            type="text"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
            className={!error ? styles.usernameInput : styles.usernameInputError}
          />

          <input
            name="email"
            type="number"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className={!error ? styles.usernameInput : styles.usernameInputError}
          />
          <button onClick={() => { setInputtingPassword(true); setWasInputtingPassword(true); }} className={styles.claimButton}>
            <span>➜</span>
          </button>
        </div>

        <div className={`${styles.FormPassword} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
          <span>
            <label onClick={() => setInputtingPassword(false)} htmlFor="password" className={styles.usernameLabel}> &lt;--- &lt;-- kembali </label>
            <label htmlFor="password" className={styles.usernameLabel}>-------------------------</label>
          </span>

          <input
            name="password"
            type="password"
            placeholder="Ganti password"
            onChange={handleChange}
            maxLength="30"
            className={!error ? styles.usernameInput : styles.usernameInputError}
          />
          <input
            name="password"
            type="password"
            placeholder="Ulangi password"
            onChange={handleChange}
            maxLength="30"
            className={!error ? styles.usernameInput : styles.usernameInputError}
          />
          <button
            onClick={handleSubmit}
            className={`${styles.claimButton} ${loading ? styles.loading : ''}`}
            disabled={loading}
          >
            <span>{loading ? 'Loading...' : 'Simpan'}</span>
          </button>
        </div>
      </div>
      </div>
      </div>
        // <div className={styles.container}>
        //     <h2>Update Your Account</h2>
        //     <form onSubmit={handleSubmit} className={styles.form}>
        //         <label className={styles.formLabel}>
        //             Username:
        //             <input
        //                 type="text"
        //                 name="username"
        //                 value={formData.username}
        //                 onChange={handleChange}
        //                 className={styles.formInput}
        //             />
        //         </label>

        //         {showEmail && (
        //             <label className={styles.formLabel}>
        //                 Email:
        //                 <input
        //                     type="email"
        //                     name="email"
        //                     value={formData.email}
        //                     onChange={handleChange}
        //                     className={styles.formInput}
        //                 />
        //             </label>
        //         )}

        //         <label className={styles.formLabel}>
        //             Password:
        //             <input
        //                 type="password"
        //                 name="password"
        //                 value={formData.password}
        //                 onChange={handleChange}
        //                 className={styles.formInput}
        //             />
        //         </label>

        //         {/* Display success message */}
        //         {successMessage && <div className={styles.successMessage}>{successMessage}</div>}

        //         {/* Display error message */}
        //         {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

        //         <button type="submit" className={styles.submitButton}>
        //             Submit
        //         </button>
        //     </form>
        // </div>
    );
};

export default AccountUpdatePage;
