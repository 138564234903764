// src/CafePage.js

import React, { useState, useEffect } from "react";
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "../App.css";

import API_BASE_URL from '../config';
import Watermark from "../components/Watermark";
import { getImageUrl, createItem, updateItem, moveItemType } from "../helpers/itemHelper.js";
import SearchInput from "../components/SearchInput";
import ItemTypeLister from "../components/ItemTypeLister";
import { MusicPlayer } from "../components/MusicPlayer";
import ItemLister from "../components/ItemLister";
import Header from "../components/Header";

import { ThreeDots } from "react-loader-spinner";

import { getLocalStorage, updateLocalStorage, removeLocalStorage } from "../helpers/localStorageHelpers";
import { unsubscribeUser } from "../helpers/subscribeHelpers.js";
import WelcomePage from "./WelcomePage.js";
import { useNavigationHelpers } from "../helpers/navigationHelpers";

function CafePage({
  shopId,
  table,
  sendParam,
  welcomePageConfig,
  shopName,
  shopOwnerId,
  setShopItems,
  shopItems,
  shopClerks,
  socket,
  user,
  guestSides,
  guestSideOfClerk,
  removeConnectedGuestSides,
  setModal,
  loading,
  queue,
  cartItemsLength,
  totalPrice,
  lastTransaction
}) {



  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { shopIdentifier, tableCode } = useParams();
  sendParam({ shopIdentifier, tableCode });

  const {
    goToCart,
    goToTransactions
  } = useNavigationHelpers(shopIdentifier, table.tableCode);

  const navigate = useNavigate();

  const [screenMessage, setScreenMessage] = useState("");

  const [isSpotifyNeedLogin, setNeedSpotifyLogin] = useState(true);
  const [isExceededDeadline, setIsExceededDeadline] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [filterId, setFilterId] = useState(0);
  const [isStarted, setIsStarted] = useState(false);

  const [config, setConfig] = useState({});

  const [beingEditedType, setBeingEditedType] = useState(0);

  const checkWelcomePageConfig = () => {
    const parsedConfig = JSON.parse(welcomePageConfig);
    if (parsedConfig.isWelcomePageActive == "true") {
      const clicked = sessionStorage.getItem("getStartedClicked");
      if (!clicked) {
        sessionStorage.setItem("getStartedClicked", true);
        document.body.style.overflow = "hidden";
        setIsStarted(true);
      }
    }
  };

  useEffect(() => {
    if (welcomePageConfig) {
      const parsedConfig = JSON.parse(welcomePageConfig);
      setConfig({
        image: getImageUrl(parsedConfig.image) || "",
        welcomingText: parsedConfig.welcomingText || "Enjoy your coffee!",
        backgroundColor: parsedConfig.backgroundColor || "#ffffff",
        textColor: parsedConfig.textColor || "#000000",
        isActive: parsedConfig.isWelcomePageActive === "true",
      });
    }
    checkWelcomePageConfig();
  }, [welcomePageConfig]);


  useEffect(() => {
    function fetchData() {
      console.log(user.userId == shopOwnerId)
      setModal("create_item");

    }
    console.log(getLocalStorage('auth'))
    if (getLocalStorage("auth") != null) {
      const executeFetch = async () => {
        while (user.length == 0) {
          await new Promise((resolve) => setTimeout(resolve, 100)); // Wait until the user is not null
        }
        console.log(user)
        console.log('open')
        if (user.length != 0 && user.userId == shopOwnerId && shopItems.length == 0) fetchData();
      };
      executeFetch();
    }
  }, [user, shopItems]);

  useEffect(() => {
    if (token) {
      updateLocalStorage("auth", token);
    }
  }, [token]);

  const handleLogout = () => {
    removeLocalStorage("auth");
    unsubscribeUser();
    navigate(0);
  };

  useEffect(() => {
    async function fetchData() {
      socket.on("joined-room", (response) => {
        const { isSpotifyNeedLogin, isExceededDeadline } = response;
        setNeedSpotifyLogin(isSpotifyNeedLogin);
        if (isExceededDeadline) setModal('message',{returnMessage:'Kafe sedang tidak tersedia'});
        setIsExceededDeadline(isExceededDeadline);
      });
    }

    if (socket) fetchData();
  }, [socket]);

  const handleGetStarted = () => {
    setIsStarted(false);

    document.body.style.overflow = "auto";
  };

  const moveItemTypeHandler = async (itemTypeId, direction, index) => {
    const previousItems = [...shopItems];

    // Update local state immediately
    const newItems = [...shopItems];

    let targetIndex;
    if (direction === 'up' && index > 0) {
      targetIndex = index - 1;
    } else if (direction === 'down' && index < newItems.length - 1) {
      targetIndex = index + 1;
    }
    console.log(index);
    console.log(targetIndex);
    if (targetIndex !== undefined) {
      // Swap items
      [newItems[index], newItems[targetIndex]] = [newItems[targetIndex], newItems[index]];
      newItems[index].order = targetIndex;
      newItems[targetIndex].order = index;

      setShopItems(newItems);

      // Call the API to move the item type
      try {
        await moveItemType(itemTypeId, previousItems[targetIndex].itemTypeId, index, targetIndex);
      } catch (error) {
        console.error('Error moving item type:', error);
        // Revert the changes if the backend fails
        setShopItems(previousItems);
      }
    }
  };

  if (loading)
    return (
      <div className="Loader">
        <div className="LoaderChild">
          <ThreeDots />
          <h1>{screenMessage}</h1>
        </div>
      </div>
    );
  else
    return (
      <>
        {welcomePageConfig && isStarted ? (
          <WelcomePage
            image={config.image}
            welcomingText={config.welcomingText}
            backgroundColor={config.backgroundColor}
            textColor={config.textColor}
            isActive={config.isActive}
            onGetStarted={handleGetStarted}
            isFullscreen={true}
          />
        ) : (
          welcomePageConfig != null && (
            <div className={`Cafe ${isExceededDeadline ? 'grayscale' : ''}`}>
              
            {API_BASE_URL != 'https://api.kedaimaster.com' &&
              <div className="Watermark"></div>
            }
              <div className="App-header">
                <Header
                  HeaderText={"Menu"}
                  showProfile={true}
                  setModal={setModal}
                  isLogout={handleLogout}
                  shopId={shopId}
                  shopName={shopName}
                  shopImage={config.image}
                  shopOwnerId={shopOwnerId}
                  shopClerks={shopClerks}
                  tableCode={table.tableCode}
                  user={user}
                  guestSides={guestSides}
                  guestSideOfClerk={guestSideOfClerk}
                  removeConnectedGuestSides={removeConnectedGuestSides}
                  setIsEditMode={(e) => setIsEditMode(e)}
                  isEditMode={isEditMode}
                />
                <MusicPlayer
                  socket={socket}
                  shopId={shopId}
                  user={user}
                  shopOwnerId={shopOwnerId}
                  isSpotifyNeedLogin={isSpotifyNeedLogin}
                  queue={queue}
                />
                <ItemTypeLister
                  user={user}
                  shopOwnerId={shopOwnerId}
                  shopId={shopId}
                  itemTypes={shopItems}
                  setShopItems={setShopItems}
                  isEditMode={isEditMode}
                  onFilterChange={(e) => setFilterId(e)}
                  filterId={filterId}
                  beingEditedType={beingEditedType}
                  setBeingEditedType={setBeingEditedType}
                />
                {/* <div style={{ marginTop: "15px" }}></div> */}
                <div>
                  {shopItems
                    .filter(
                      (itemType) =>
                        filterId == 0 || itemType.itemTypeId === filterId
                    )
                    .map((itemType, index) => (
                      <ItemLister
                        index={index}
                        indexTotal={shopItems.length}
                        shopId={shopId}
                        shopOwnerId={shopOwnerId}
                        user={user}
                        key={itemType.itemTypeId}
                        itemTypeId={itemType.itemTypeId}
                        typeName={itemType.name}
                        typeImage={itemType.image}
                        setShopItems={setShopItems}
                        itemList={itemType.itemList}
                        typeVisibility={itemType.visibility}
                        moveItemTypeUp={(e) => moveItemTypeHandler(e, 'up', index)}
                        moveItemTypeDown={(e) => moveItemTypeHandler(e, 'down', index)}
                        isEditMode={isEditMode}
                        beingEditedType={beingEditedType}
                        setBeingEditedType={setBeingEditedType}
                        raw={isEditMode || filterId == 0 ? false : true}
                        handleCreateItem={(
                          itemTypeID,
                          name,
                          price,
                          selectedImage,
                          description
                        ) =>
                          createItem(
                            shopId,
                            name,
                            price,
                            selectedImage,
                            itemTypeID,
                            description
                          )
                        }
                        handleUpdateItem={(itemId, name, price, selectedImage, description, promoPrice) =>
                          updateItem(itemId, name, price, selectedImage, description, promoPrice)
                        }
                      />
                    ))}
                  {!isEditMode && (user.username || cartItemsLength > 0) &&
                    <div style={{ marginTop: '10px', height: '40px', position: 'sticky', bottom: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      {(lastTransaction != null || cartItemsLength > 0) &&
                        <div onClick={goToCart} style={{ backgroundColor: '#73a585', width: user.username ? '55vw' : '70vw', height: '40px', borderRadius: '30px', display: 'flex', justifyContent: 'space-between', padding: '0 20px' }}>
                          <div style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}>{lastTransaction != null && '+'}{cartItemsLength} item</div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '130px' }}>
                            {((lastTransaction == null || lastTransaction?.payment_type != 'paylater')) ?
                              <span style={{ whiteSpace: 'nowrap' }}>Rp{totalPrice}</span>
                              :
                              <span style={{ whiteSpace: 'nowrap' }}>Open bill</span>
                            }
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', width: '20px' }}>
                              <svg viewBox="0 0 34 34" style={{ fill: 'white', marginTop: '4px' }}>
                                <path d="M9.79175 24.75C8.09591 24.75 6.72383 26.1375 6.72383 27.8333C6.72383 29.5292 8.09591 30.9167 9.79175 30.9167C11.4876 30.9167 12.8751 29.5292 12.8751 27.8333C12.8751 26.1375 11.4876 24.75 9.79175 24.75ZM0.541748 0.0833435V3.16668H3.62508L9.17508 14.8679L7.09383 18.645C6.84717 19.0767 6.70842 19.5854 6.70842 20.125C6.70842 21.8208 8.09591 23.2083 9.79175 23.2083H28.2917V20.125H10.4392C10.2234 20.125 10.0538 19.9554 10.0538 19.7396L10.1001 19.5546L11.4876 17.0417H22.973C24.1292 17.0417 25.1467 16.4096 25.6709 15.4538L31.1901 5.44834C31.3134 5.23251 31.3751 4.97043 31.3751 4.70834C31.3751 3.86043 30.6813 3.16668 29.8334 3.16668H7.03217L5.583 0.0833435H0.541748ZM25.2084 24.75C23.5126 24.75 22.1405 26.1375 22.1405 27.8333C22.1405 29.5292 23.5126 30.9167 25.2084 30.9167C26.9042 30.9167 28.2917 29.5292 28.2917 27.8333C28.2917 26.1375 26.9042 24.75 25.2084 24.75Z"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      }
                      {user.username &&
                        <div onClick={goToTransactions} style={{ backgroundColor: '#73a585', width: '15vw', height: '40px', borderRadius: '30px', display: 'flex', justifyContent: 'center', marginLeft: lastTransaction != null || cartItemsLength > 0 ? '6px' : '0px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '38px', marginRight: '5px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', width: '20px' }}>
                              <svg viewBox="0 0 512 512">
                                <g
                                  transform="translate(0 460) scale(0.09 -0.09)"
                                  style={{ fill: 'white', marginTop: '4px' }}
                                  stroke="none"
                                >
                                  <path
                                    d="M1639 5107 c-47 -13 -70 -28 -177 -109 -119 -90 -246 -102 -381 -34
        -53 27 -83 36 -121 36 -88 0 -167 -57 -190 -138 -8 -26 -10 -620 -8 -1982 l3
        -1945 24 -38 c13 -21 42 -50 64 -65 l41 -27 1535 -5 1536 -5 58 -22 c158 -60
        291 -205 322 -352 10 -45 74 -108 119 -117 78 -14 154 25 182 93 12 27 14 398
        14 2238 0 2400 4 2243 -53 2303 -67 72 -160 81 -268 26 -52 -26 -89 -37 -138
        -41 -99 -8 -161 13 -268 93 -119 89 -140 97 -248 98 -108 0 -129 -8 -249 -98
        -107 -80 -168 -101 -267 -93 -79 7 -121 26 -231 108 -165 122 -309 119 -471
        -9 -87 -69 -138 -92 -216 -99 -99 -8 -161 13 -268 93 -48 36 -104 73 -123 81
        -49 20 -165 26 -221 10z m2274 -1226 c21 -13 50 -42 65 -64 22 -34 27 -52 27
        -106 0 -79 -25 -128 -88 -169 l-41 -27 -1165 0 -1166 0 -38 24 c-76 47 -111
        140 -88 229 14 52 76 117 123 131 20 6 475 9 1183 8 l1150 -2 38 -24z m0 -900
        c21 -13 50 -42 65 -64 22 -34 27 -52 27 -106 0 -79 -25 -128 -88 -169 l-41
        -27 -1165 0 -1166 0 -38 24 c-76 47 -111 140 -88 229 14 52 76 117 123 131
        20 6 475 9 1183 8 l1150 -2 38 -24z m4 -903 c62 -41 88 -90 88 -168 0 -78 -26
        -127 -88 -168 l-41 -27 -665 0 -666 0 -38 24 c-76 47 -111 140 -88 229 14 51
        76 117 123 131 20 6 291 9 684 8 l650 -2 41 -27z"
                                  />
                                  <path
                                    d="M592 489 c-47 -14 -109 -79 -123 -131 -33 -122 37 -265 159 -325 l57
        -28 1815 -2 c1736 -2 1813 -2 1765 15 -125 43 -186 126 -205 279 -12 89
        -39 138 -97 174 l-38 24 -1650 2 c-1023 1 -1662 -2 -1683 -8z"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
              <Watermark/>
            </div>
          )
        )}
      </>
    );
}

export default CafePage;
