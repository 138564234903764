import React from 'react';
import StepByStep from './StepByStep';

const Watermark = ({dontShowName}) => {
  return (
    <div style={{ zIndex: 5, marginTop: '30px', backgroundColor: 'rgb(222 237 100)', fontWeight: 700, fontSize: '15px', lineHeight: '1rem', letterSpacing: '-1px', color: 'rgb(37, 79, 26)', padding: '10px' }}>
      {!dontShowName && <div>KEDAIMASTER.COM</div>}
      <div style={{ display: 'flex' }}>
        <div style={{ width: '45vw', marginTop: '10px', fontSize: '5.5vw', lineHeight: '33px' }}>Gak perlu rasain antri panjang yang bikin bosen lagi. Tinggal scan QR yang ada di meja, eh tiba tiba kamu udah kenyang wkwkkw</div>
        <StepByStep />
      </div>
      <div style={{ marginTop: '8px' }}>© 2025 KEDIRITECHNOPARK.COM</div>
    </div>
  );
};

export default Watermark;
