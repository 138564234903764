import React, { useState, useRef, useEffect } from "react";
import styles from "./Dropdown.module.css"; // Import the CSS Module

const icons = {
    cash: (
        <svg
            fill="#000000"
            viewBox="0 0 96 96"
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
        >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
                {" "}
                <title></title>{" "}
                <g>
                    {" "}
                    <path d="M90,12H6a5.9966,5.9966,0,0,0-6,6V78a5.9966,5.9966,0,0,0,6,6H90a5.9966,5.9966,0,0,0,6-6V18A5.9966,5.9966,0,0,0,90,12ZM24,72A12.0119,12.0119,0,0,0,12,60V36A12.0119,12.0119,0,0,0,24,24H72A12.0119,12.0119,0,0,0,84,36V60A12.0119,12.0119,0,0,0,72,72Z"></path>{" "}
                    <path d="M48,36A12,12,0,1,0,60,48,12.0119,12.0119,0,0,0,48,36Z"></path>{" "}
                </g>{" "}
            </g>
        </svg>
    ),
    cashless: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width="20px"
            height="20px"
            viewBox="0 0 21000 7750"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <style type="text/css">
                    {`
          .fil0 { fill: black; fill-rule: nonzero; }
        `}
                </style>
            </defs>
            <g id="__x0023_Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                    className="fil0"
                    d="M20140 4750l0 -667 0 -1333 -2000 0 -1333 0 0 -667 3333 0 0 -1333 -3333 0 -2000 0 0 1333 0 667 0 1333 2000 0 1333 0 0 667 -3333 0 0 1333 3333 0 2000 0 0 -1333zm527 -417l0 2167c0,44 -18,87 -49,118 -31,31 -74,49 -118,49l-2167 0 0 333 2500 0c44,0 87,-18 118,-49 31,-31 49,-74 49,-118l0 -2500 -333 0zm-18000 -4333l-2500 0c-44,0 -87,18 -118,49 -31,31 -49,74 -49,118l0 2500 333 0 0 -2167c0,-44 18,-87 49,-118 31,-31 74,-49 118,-49l2167 0 0 -333zm2140 7750l1333 0 0 -3000 -1333 0 0 3000zm1167 -7000l-3167 0 0 1333 2000 0 0 2000 1333 0 0 -3167c0,-44 -18,-87 -49,-118 -31,-31 -74,-49 -118,-49zm-3833 0l-1167 0c-44,0 -87,18 -118,49 -31,31 -49,74 -49,118l0 5000c0,44 18,87 49,118 31,31 74,49 118,49l3167 0 0 -1333 -2000 0 0 -4000zm667 3333l1333 0 0 -1333 -1333 0 0 1333zm333 -1000l0 0 667 0 0 667 -667 0 0 -667zm3667 -2333l0 1333 4000 0 0 667 -2667 0 -1333 0 0 1333 0 2000 1333 0 0 -1980 2000 1980 2000 0 -2087 -2000 753 0 1333 0 0 -1333 0 -667 0 -1333 -1333 0 -4000 0zm6000 5333l1333 0 0 -5333 -1333 0 0 5333z"
                />
            </g>
        </svg>
    ),
    paylater: (
        <svg
            fill="black"
            height="20px"
            width="20px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 409.221 409.221"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            enableBackground="new 0 0 409.221 409.221"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
                <path d="m115.921,208.425c0-5.523 4.477-10 10-10h56.421c5.523,0 10,4.477 10,10s-4.477,10-10,10h-56.421c-5.523,0-10-4.477-10-10zm10,54.838h56.421c5.523,0 10-4.477 10-10s-4.477-10-10-10h-56.421c-5.523,0-10,4.477-10,10s4.477,10 10,10zm32.2-159.536c0-19.248 20.421-34.326 46.49-34.326 26.068,0 46.488,15.078 46.488,34.326s-20.42,34.326-46.488,34.326c-26.069,0-46.49-15.078-46.49-34.326zm20,0c0,6.762 11.329,14.326 26.49,14.326 15.16,0 26.488-7.563 26.488-14.326s-11.328-14.326-26.488-14.326c-15.162-1.42109e-14-26.49,7.564-26.49,14.326zm-52.2,81.176h56.421c5.523,0 10-4.477 10-10s-4.477-10-10-10h-56.421c-5.523,0-10,4.477-10,10s4.477,10 10,10zm157.381,155.665h-32.818c-5.522,0-10,4.477-10,10s4.478,10 10,10h32.818c5.522,0 10-4.477 10-10s-4.478-10-10-10zm59.627-330.568v389.221c0,5.523-4.478,10-10,10h-256.637c-5.523,0-10-4.477-10-10v-389.221c0-5.523 4.477-10 10-10h36.662c5.523,0 10,4.477 10,10v14.327h16.662v-14.327c0-5.523 4.477-10 10-10h36.663c5.523,0 10,4.477 10,10v14.327h16.663v-14.327c0-5.523 4.478-10 10-10h36.662c5.522,0 10,4.477 10,10v14.327h16.663v-14.327c0-5.523 4.478-10 10-10h36.661c5.523,0 10.001,4.477 10.001,10zm-20,10h-16.661v14.327c0,5.523-4.478,10-10,10h-36.663c-5.522,0-10-4.477-10-10v-14.327h-16.662v14.327c0,5.523-4.478,10-10,10h-36.663c-5.523,0-10-4.477-10-10v-14.327h-16.663v14.327c0,5.523-4.477,10-10,10h-36.662c-5.523,0-10-4.477-10-10v-14.327h-16.663v369.221h236.637v-369.221zm-39.627,178.425h-32.818c-5.522,0-10,4.477-10,10s4.478,10 10,10h32.818c5.522,0 10-4.477 10-10s-4.478-10-10-10zm0,44.838h-32.818c-5.522,0-10,4.477-10,10s4.478,10 10,10h32.818c5.522,0 10-4.477 10-10s-4.478-10-10-10zm0,48.653h-157.381c-5.523,0-10,4.477-10,10s4.477,10 10,10h157.381c5.522,0 10-4.477 10-10s-4.478-10-10-10z"></path>
            </g>
        </svg>
    ),
};

const Dropdown = ({ onChange, paymentMethods, setDropdownKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("cash");
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownKey(); 
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleOptionClick = (value) => {
        setSelectedOption(value);
        setIsOpen(false);
        
        if (onChange) onChange({ target: { value } });
    };

    const options = [
        { value: "cash", label: "Tunai", icon: icons.cash },
        { value: "cashless", label: "Non tunai", icon: icons.cashless },
        { value: "paylater", label: "Open bill", icon: icons.paylater },
    ];

    return (
        <div className={styles.dropdown} ref={dropdownRef}>
            <div className={styles.dropdownButton} onClick={toggleDropdown}>
                <a>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.svg}>
                            {icons[selectedOption]}
                        </div>
                        {options.find((option) => option.value === selectedOption)?.label}
                    </div>
                    <span
                        className={styles.arrow}
                        style={{
                            color: 'black'
                        }}
                    >
                        {isOpen ? "▲" : "▼"}
                    </span>

                </a>
            </div>
            {isOpen && (
                <div className={styles.dropdownContent}>
                    {options
                        .filter((option) => {
                            // Filter out 'cashless' option if QRIS is not available
                            if (option.value === 'cashless' && !paymentMethods.isQRISavailable) {
                                return false;
                            }
                            // Filter out 'paylater' option if Open Bill is not available
                            if (option.value === 'paylater' && !paymentMethods.isOpenBillAvailable) {
                                return false;
                            }
                            return true;
                        })
                        .map((option) => (
                            <a onClick={() => handleOptionClick(option.value)} key={option.value}>
                                <div className={styles.svg}>
                                    {option.icon}
                                </div>
                                {option.label}
                            </a>
                        ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
