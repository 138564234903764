// LinktreePage.js
import React, { useState, useEffect } from 'react';
import styles from './Join.module.css'; // Import the module.css file
import { checkCoupon, logCouponForUser } from '../helpers/couponHelpers';  // Import the new helper
import Coupon from '../components/Coupon';

import CryptoJS from 'crypto-js';

import {
  getLocalStorage,
} from "../helpers/localStorageHelpers";

const LinktreePage = ({ data, setModal }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isOnlyClaimCoupon, setIsOnlyClaimCoupon] = useState(false);
  const [isUsingCoupon, setIsUsingCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponStatus, setCouponStatus] = useState(0);
  const [couponDetails, setCouponDetails] = useState(null);

  useEffect(() => {
    if (couponCode !== '') return;
  
    const modal = queryParams.get('modal');
    const code = queryParams.get('c');
  
    console.log("Received coupon code:", code);
  
    if (modal === 'claim-coupon') {
      if (!getLocalStorage('auth')) {
        // Preserve the couponCode query param while navigating to the claim-coupon modal
        if (code) setModal('join', { c: code });
        else setModal('join');
      }
  
      setIsOnlyClaimCoupon(true);
      setIsUsingCoupon(true);  // Automatically switch to the coupon input state
    } else {
      if (getLocalStorage('auth')) {
        // Preserve the couponCode query param while navigating to the claim-coupon modal
        if (code) setModal('claim-coupon', { c: code });
        else setModal('claim-coupon');
      }
    }
  
    if (code) {
      // URL decode the coupon code (it might be URL-encoded)
      let decodedCode = code.replace('LDNWAlASJDNdaw','+').replace('XCLZBKlaWDJ','/').replace('LDSsadANJlas','=');
  
      // Your AES-256 key (ensure this is kept secret and secure!)
      const secretKey = 'xixixi666'; // 32 characters for AES-256
  
      // Decrypt the coupon code
      const decryptedBytes = CryptoJS.AES.decrypt(decodedCode, secretKey);
      const decryptedCode = decryptedBytes.toString(CryptoJS.enc.Utf8);
  
      console.log("Decrypted Code:", decryptedCode);
  
      if (decryptedCode) {
        setCouponCode(decryptedCode);
        setIsUsingCoupon(true);  // Automatically switch to the coupon input state
        handleCheckCoupon(decryptedCode); // Automatically check the coupon code
      } else {
        console.log("Failed to decrypt the coupon code");
      }
    }
  }, [queryParams]);
  
  

  // Handle manual coupon code check
  const handleCheckCoupon = async (code = couponCode) => {
    const result = await checkCoupon(code);  // Call the helper
    setCouponStatus(result.coupon ? 200 : 404);
    setCouponDetails(result.coupon);
  };

  // Handle manual coupon code check
  const handleLogCouponForUser = async (code = couponCode) => {
    const result = await logCouponForUser(code);  // Call the helper
    
    return result.success;
  };

  // Listen for query parameter changes (using the `location` object)
  useEffect(() => {
    const handlePopState = () => {
      const newQueryParams = new URLSearchParams(window.location.search);
      const newCouponCode = newQueryParams.get('couponCode');
      if (!newCouponCode) {
        setIsUsingCoupon(false);
        setCouponCode('');
        setCouponDetails(null);
        setCouponStatus(0);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className={styles.linktreePage}>
      {!isUsingCoupon ? (
        <div className={styles.dashboardContainer}>
          <div className={styles.mainHeading}>Nikmati Kemudahan Mengelola Kafe</div>
          <div className={styles.subHeadingTransparent}>
            Daftarkan kedaimu sekarang dan mulai gunakan semua fitur unggulan kami.
          </div>
          <div className={styles.linktreeForm}>
            <button onClick={()=>window.open("https://api.whatsapp.com/send?phone=6281318894994&text=Saya%20ingin%20coba%20gratis%203%20bulan")} className={styles.claimButton}>
              <span>Dapatkan voucher gratis 3 bulan</span>
            </button>
          </div>
          <div className={styles.footer}>
            <div className={styles.footerLinks}>
              <a
                href="https://linktr.ee/discover/trending"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Pelajari lebih lanjut
              </a>
              <a
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor behavior
                  setIsUsingCoupon(true);
                }}
                className={styles.footerLink}
              >
                Gunakan Voucher
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.dashboardContainer}>
          <div className={styles.voucherHeading}>{isOnlyClaimCoupon ? 'Aktifkan Voucher' : 'Daftar Dengan Voucher'}</div>
          <div className={styles.subHeading}>
            Voucher dapat digunakan untuk pembuatan akun bisnis maupun untuk memperpanjang masa berlangganan.
          </div>
          {couponStatus === 0 ? (
            <form className={styles.linktreeForm} onSubmit={(e) => e.preventDefault()}>
              <label htmlFor="coupon" className={styles.usernameLabel}>
                ----------------------------------------
              </label>
              <input
                id="coupon"
                placeholder="Kode Voucher"
                maxLength="30"
                className={styles.usernameInput}
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button onClick={() => handleCheckCoupon()} className={styles.claimButton}>
                <span>Cek</span>
              </button>
            </form>
          ) : (
            <>
              <Coupon
                code={couponDetails?.code || null}
                value={couponDetails?.discountValue}
                period={couponDetails?.discountPeriods}
                type={couponDetails?.type}
                expiration={couponDetails?.expirationDate}
              />
              {couponStatus === 200 &&
                <div className={styles.linktreeForm}>
                  <label htmlFor="username" className={styles.usernameLabel}>
                    --------------------------------------------
                  </label>
                  <button
                    className={styles.claimButton}
                    style={{ width: '266px' }}
                    onClick={async () => {
                      if (!isOnlyClaimCoupon) {
                        const secretKey = 'xixixi666'; // Your AES-256 key (32 characters)
                    
                        // Encrypt couponCode inline
                        const encryptedCouponCode = CryptoJS.AES.encrypt(couponCode, secretKey).toString();
                        console.log(encryptedCouponCode);
                    
                        // If it's not for claiming a coupon, trigger the create user modal logic
                        setModal('create_user', { codeStatus: 200, c: encryptedCouponCode });
                      } else {
                        try {
                          // Otherwise, handle the coupon for user creation
                          setModal('loading');
                    
                          const loggingcoupon = await handleLogCouponForUser(); // Await the coupon logging process
                          setModal('message', { 
                            returnMessage: loggingcoupon ? 'Kupon berhasil ditambahkan' : 'Kupon gagal ditambahkan' 
                          });
                        } catch (error) {
                          console.error('Error during coupon handling:', error);
                          setModal('message', { returnMessage: 'Kupon gagal ditambahkan' });
                        }
                      }
                    }}                    
                  >
                    <span>{isOnlyClaimCoupon ? 'Aktifkan untuk akun ini' : 'Buat akun bisnis dengan voucher ini'}</span>
                  </button>

                </div>
              }
            </>
          )}
          <div className={styles.footer}>
            <div className={styles.footerLinks}>
              {!getLocalStorage('auth') &&
                <a
                  href="https://linktr.ee/discover/trending"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.footerLink}
                >
                  Sudah punya akun bisnis?
                </a>
              }
              <a
                href="https://linktr.ee/discover/trending"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Pelajari lebih lanjut
              </a>
              {(!isOnlyClaimCoupon || couponStatus != 0) &&
                <a
                  onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.delete('couponCode');
                    url.searchParams.delete('codeStatus');
                    window.history.pushState({}, '', url.toString());
                    setIsUsingCoupon(couponStatus === 0 ? false : true);
                    setCouponCode('');
                    setCouponDetails(null);
                    setCouponStatus(0);
                  }}
                  className={styles.footerLink}
                >
                  Kembali
                </a>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinktreePage;
