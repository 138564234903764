import React from 'react';
import styles from './StepByStep.module.css'; // Import the CSS Module

const StepByStep = () => {
  return (
  <div className={styles.container}>
    <div className={styles.screen}>
      <div className={styles.main}>
        <img src="https://i.ibb.co.com/F4VK6KHs/header.jpg" alt="Header" />
        <div className={styles.player}>
          <img src="https://i.ibb.co.com/6cGq6byM/player1.jpg" alt="Player 1" />
          <img src="https://i.ibb.co.com/0VDjJdXV/player2.jpg" alt="Player 2" />
          <img src="https://i.ibb.co.com/8D3mSp4g/player3.jpg" alt="Player 3" />
          <img src="https://i.ibb.co.com/XxS1DhRy/player4.jpg" alt="Player 4" />
        </div>
        <div>
          <img src="https://i.ibb.co.com/4z5zdsS/body.jpg" alt="Body" />
          <div className={styles.escappucino}>
            <img src="https://i.ibb.co.com/yFvrPX8z/pesan.png" alt="Escappucino 1" />
            <img src="https://i.ibb.co.com/rRwPHtY7/pesan-1.png" alt="Escappucino 2" />
          </div>
          <div className={styles.chickenkatsu}>
            <img src="https://i.ibb.co.com/yFvrPX8z/pesan.png" alt="Chickenkatsu 1" />
            <img src="https://i.ibb.co.com/rRwPHtY7/pesan-1.png" alt="Chickenkatsu 2" />
          </div>
        </div>
      </div>
      <div className={styles.cartbutton}>
        <img src="https://i.ibb.co.com/zVrfGjZw/New-Project.png" alt="Cart Button" />
        <img src="https://i.ibb.co.com/Y7wbjGDz/cart-2.png" alt="Cart Icon" />
      </div>
      <div className={styles.cart}>
        <img src="https://i.ibb.co.com/F4Hb7Tqg/cart.jpg" alt="Cart Image" />
        <img src="https://i.ibb.co.com/Mxrjc9Dc/checkout.png" alt="Checkout" />
      </div>
      <div className={styles.transaction}>
        <img src="https://i.ibb.co.com/1p7pnnD/transaction.png" alt="Transaction" />
      </div>
    </div>
    <div className={styles.secondscreen}>
      <div className={styles.secondmain}>
        <img src="https://i.ibb.co.com/LDw21htp/New-Project.jpg"/>
      </div>
    </div>
    </div>
  );
};

export default StepByStep;
