import React, { useState } from "react";
import styles from './Join.module.css';
import Coupon from '../components/Coupon';

import CryptoJS from 'crypto-js';
import { createCoupon } from "../helpers/couponHelpers.js"

function getAuthToken() {
  return localStorage.getItem("auth");
}
const CreateCouponPage = () => {
  const [discountType, setDiscountType] = useState("percentage");
  const [discountValue, setDiscountValue] = useState("");
  const [discountPeriods, setDiscountPeriods] = useState("");
  const [couponDetails, setCouponDetails] = useState(null);


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [wasInputtingPassword, setWasInputtingPassword] = useState(false);
  const [inputtingPassword, setInputtingPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [codeExpectation, setCodeExpectation] = useState('');
  const [couponUrl, setCouponUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Call the helper function to create the coupon
    const result = await createCoupon(discountType, discountValue, discountPeriods, codeExpectation);
    
    if (result.success) {
      setCouponDetails(result.coupon);
      
      const secretKey = 'xixixi666'; // AES-256 key (32 characters)
  
      // Encrypt the coupon code inline using CryptoJS
      let encryptedCouponCode = CryptoJS.AES.encrypt(result.coupon.code, secretKey).toString().replace('+','LDNWAlASJDNdaw').replace('/','XCLZBKlaWDJ').replace('=','LDSsadANJlas');
      // Encode the encrypted coupon code for URL usage:
      // 1. First, encodeURIComponent to handle URL-specific characters.
      // 2. Then, replace special characters like '+' and '/' that might cause issues.
      let encodedCouponCode = encodeURIComponent(encryptedCouponCode);
      
      // Construct the URL with the encoded coupon code as a query parameter
      const urlWithCoupon = `https://coupon.kedaimaster.com/coupon?c=${encodedCouponCode}`;
  
      // Optionally, set the URL to use with the coupon
      setCouponUrl(urlWithCoupon);
  
      console.log("Generated URL with Encrypted Coupon Code:", urlWithCoupon);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  
  
  
  

  return (
    <div className={styles.linktreePage}>
      <div className={styles.dashboardContainer}>
        <div className={styles.mainHeading}>Buat Voucher {couponDetails != null && 'Berhasil'}</div>
        <div className={styles.subHeadingTransparent}>
          Daftarkan kedaimu sekarang dan mulai gunakan semua fitur unggulan kami.
        </div>

        {couponDetails != null ?
          <>
            <Coupon
              code={couponDetails?.code || null}
              value={couponDetails?.discountValue}
              period={couponDetails?.discountPeriods}
              type={couponDetails?.type}
              expiration={couponDetails?.expirationDate}
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(couponUrl)
                  .then(() => {
                    // Optional: Show a message that the text has been copied
                    alert("Coupon URL copied to clipboard!");
                  })
                  .catch((err) => {
                    console.error("Failed to copy text: ", err);
                  });
              }}
              className={styles.claimButton}
              style={{ marginBottom: '10px' }}
            >
              <span>Salin URL VOUCHER</span>
            </button></>

          :
          <div className={styles.LoginForm}>
            <div className={`${styles.FormUsername} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
              <label htmlFor="username" className={styles.usernameLabel}>----------------------------------------------</label>
              <select
                className={!error ? styles.usernameInput : styles.usernameInputError}
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
                style={{width: '100%'}}
              >
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed</option>
              </select>

              <input
                type="number"
                placeholder="Nilai voucher"
                value={discountValue}
                onChange={(e) => setDiscountValue(e.target.value)}
                className={!error ? styles.usernameInput : styles.usernameInputError}
              />
              <button onClick={() => { setInputtingPassword(true); setWasInputtingPassword(true); }} className={styles.claimButton}>
                <span>➜</span>
              </button>
            </div>

            <div className={`${styles.FormPassword} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
              <span>
                <label onClick={() => setInputtingPassword(false)} htmlFor="password" className={styles.usernameLabel}> &lt;--- &lt;-- kembali </label>
                <label htmlFor="password" className={styles.usernameLabel}> &nbsp; ----------------- &nbsp; </label>
              </span>

              <input
                type="number"
                placeholder="Periode diskon (minggu)"
                value={discountPeriods}
                onChange={(e) => setDiscountPeriods(e.target.value)}
                maxLength="30"
                className={!error ? styles.usernameInput : styles.usernameInputError}
              />
              <input
                type="text"
                placeholder="Ekspektasi kode"
                value={codeExpectation}
                onChange={(e) => setCodeExpectation(e.target.value)}
                maxLength="30"
                className={!error ? styles.usernameInput : styles.usernameInputError}
              />
              <button
                onClick={handleSubmit}
                className={`${styles.claimButton} ${loading ? styles.loading : ''}`}
                disabled={loading}
              >
                <span>{loading ? 'Loading...' : 'Buat'}</span>
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default CreateCouponPage;
