// LinktreePage.js
import React, { useState, useEffect } from 'react';
import styles from './Join.module.css';
import { checkCoupon, createUserWithCoupon } from '../helpers/couponHelpers';  // Import the helper functions

const LinktreePage = ({ setModal }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isUsingCoupon, setIsUsingCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponStatus, setCouponStatus] = useState('');
  const [couponDetails, setCouponDetails] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [wasInputtingPassword, setWasInputtingPassword] = useState(false);
  const [inputtingPassword, setInputtingPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  // Detect query params on component mount
  useEffect(() => {
    const code = queryParams.get('c');
    if (code) {
      setCouponStatus('Coupon is valid');
      setCouponCode(code);
      setIsUsingCoupon(true); // Automatically switch to the coupon input state
    }
  }, [queryParams]);

  // Handle user creation with coupon
  const handleCreateUserWithCoupon = async (e) => {
    e.preventDefault();

    if (password !== retypePassword) {
      setCouponStatus('Passwords do not match');
      return;
    }

    setLoading(true);
    const { success, token, message } = await createUserWithCoupon(username, email, password, couponCode);

    if (success) {
      setCouponStatus('User created successfully with coupon');
      localStorage.setItem('auth', token);

      // Clean the URL by removing query parameters and hash
      const cleanUrl = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', cleanUrl);

      // Reload the page with the cleaned URL (no query params or hash)
      window.location.reload();
    } else {
      setCouponStatus(message || 'Error creating user');
      setModal('join', { couponCode });
    }

    setLoading(false);
  };

  return (
    <div className={styles.linktreePage}>
      <div className={styles.dashboardContainer}>
        <div className={styles.mainHeading}>Gunakan Voucher</div>
        <div className={styles.subHeadingTransparent}>
          Daftarkan kedaimu sekarang dan mulai gunakan semua fitur unggulan kami.
        </div>

        <div className={styles.LoginForm}>
          <div className={`${styles.FormUsername} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
            <label htmlFor="username" className={styles.usernameLabel}>---- Daftar -------------------------------</label>
            <input
              type="text"
              placeholder="Username"
              maxLength="30"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <button onClick={() => { setInputtingPassword(true); setWasInputtingPassword(true); }} className={styles.claimButton}>
              <span>➜</span>
            </button>
          </div>

          <div className={`${styles.FormPassword} ${inputtingPassword ? styles.animateForm : wasInputtingPassword ? styles.reverseForm : ''}`}>
            <span>
              <label onClick={() => setInputtingPassword(false)} htmlFor="password" className={styles.usernameLabel}> &lt;--- &lt;-- kembali </label>
              <label htmlFor="password" className={styles.usernameLabel}> &nbsp; ----------------- &nbsp; </label>
            </span>

            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength="30"
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <input
              type="password"
              placeholder="Ketik ulang password"
              value={retypePassword}
              onChange={(e) => setRetypePassword(e.target.value)}
              maxLength="30"
              className={!error ? styles.usernameInput : styles.usernameInputError}
            />
            <button
              onClick={handleCreateUserWithCoupon}
              className={`${styles.claimButton} ${loading ? styles.loading : ''}`}
              disabled={loading}
            >
              <span>{loading ? 'Loading...' : 'Masuk'}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinktreePage;
