import React, { useRef, useEffect, useState } from "react";
import styles from "./Invoice.module.css";
import { useParams } from "react-router-dom"; // Changed from useSearchParams to useLocation
import { ThreeDots, ColorRing } from "react-loader-spinner";

import ItemLister from "../components/ItemLister";

import { getCartDetails } from "../helpers/itemHelper";

import { getPaymentMethods } from "../helpers/cafeHelpers.js";

import {
  handlePaymentFromClerk,
  handlePaymentFromGuestSide,
  handlePaymentFromGuestDevice,
  handleExtendFromGuestDevice,
  handleCloseBillFromGuestDevice
} from "../helpers/transactionHelpers";

import { getItemsByCafeId } from "../helpers/cartHelpers.js";

import Dropdown from "./Dropdown.js";
import { useNavigationHelpers } from "../helpers/navigationHelpers";


export default function Invoice({ shopId, setModal, table, sendParam, deviceType, socket, shopItems, setShopItems }) {
  const { shopIdentifier, tableCode } = useParams();

  sendParam({ shopIdentifier, tableCode });

  const {
    goToShop
  } = useNavigationHelpers(shopIdentifier, table.tableCode);

  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false); // State for payment button loading animation

  const textareaRef = useRef(null);
  const [orderType, setOrderType] = useState("pickup");
  const [orderMethod, setOrderMethod] = useState("cash");
  const [tableNumber, setTableNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [dropdownKey, setDropdownKey] = useState(0);

  const [paymentMethods, setPaymentMethods] = useState(null);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const methods = await getPaymentMethods(shopId);
        console.log(methods)
        const lastTransaction = JSON.parse(localStorage.getItem('lastTransaction'));
        if (lastTransaction?.payment_type == 'paylater') methods.isOpenBillAvailable = false;
        setPaymentMethods(methods)

      } catch (err) {

      }
    };

    if (shopId) {
      fetchPaymentMethods();
    }
  }, [shopId, dropdownKey]);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cart = getItemsByCafeId(shopId);
        const itemMap = new Map(cart.map(item => [item.itemId, item.qty]));

        // Step 2: Filter and transform shopItems
        const filteredItems = shopItems
          .map(itemType => ({
            itemTypeId: itemType.itemTypeId,
            cafeId: itemType.cafeId,
            typeName: itemType.name,
            itemList: itemType.itemList
              .filter(item => itemMap.has(item.itemId)) // Keep only items in getItemsByCafeId
              .map(item => ({
                itemId: item.itemId,
                price: (item.promoPrice ? item.promoPrice : item.price),
                name: item.name,
                image: item.image,
                qty: itemMap.get(item.itemId), // Add qty from getItemsByCafeId
                availability: item.availability
              }))
          }))
          .filter(itemType => itemType.itemList.length > 0); // Remove empty itemTypes
        console.log(filteredItems)



        // Update local storage by removing unavailable items
        const updatedLocalStorage =
          JSON.parse(localStorage.getItem("cart")) || [];
        const newLocalStorage = updatedLocalStorage.map((cafe) => {
          if (cafe.cafeId === shopId) {
            return {
              ...cafe,
              items: cafe.items.filter((item) =>
                filteredItems.some((filtered) =>
                  filtered.itemList.some(
                    (i) => i.itemId === item.itemId && i.availability
                  )
                )
              ),
            };
          }
          return cafe;
        });
        localStorage.setItem("cart", JSON.stringify(newLocalStorage));

        window.dispatchEvent(new Event("localStorageUpdated"));
        // Calculate total price based on filtered cart items
        const totalPrice = filteredItems.reduce((total, itemType) => {
          return (
            total +
            itemType.itemList.reduce((subtotal, item) => {
              return subtotal + item.qty * (item.promoPrice ? item.promoPrice : item.price);
            }, 0)
          );
        }, 0);

        setTimeout(function () {
          setCartItems(filteredItems);
          setTotalPrice(totalPrice);
          setIsLoading(false)
        }, 100); //delay is in milliseconds 
      } catch (error) {
        console.error("Error fetching cart items:", error);
        // Handle error if needed
      }
    };

    fetchCartItems();


    const getNewestCartItems = async () => {
      try {
        // Fetch items from the cart details (latest state)
        const items = await getCartDetails(shopId);

        // Loop through each item type in the items from the cart details
        items.forEach(itemType => {
          itemType.itemList.forEach(item => {
            // Loop through the shopItems and find the corresponding itemId
            shopItems.forEach(shopItemType => {
              shopItemType.itemList.forEach(shopItem => {
                if (shopItem.itemId === item.itemId) {
                  // Update shopItems with the new data from items (e.g., availability, price)
                  shopItem.availability = item.availability;
                  shopItem.price = item.price; // If needed, update other properties like price
                  shopItem.promoPrice = item.promoPrice;
                }
              });
            });
          });
        });

        // After updating shopItems, set the new state
        setShopItems(shopItems);

        // Filter out unavailable items
        const filteredItems = items
          .map((itemType) => ({
            ...itemType,
            itemList: itemType.itemList.filter((item) => item.availability),
          }))
          .filter((itemType) => itemType.itemList.length > 0); // Remove empty itemTypes

        setIsLoading(true);
        setTimeout(function () {
          setCartItems(filteredItems);
          setIsLoading(false);
        }, 100); // delay is in milliseconds

        // Update local storage by removing unavailable items and updating prices
        const updatedLocalStorage = JSON.parse(localStorage.getItem("cart")) || [];
        const newLocalStorage = updatedLocalStorage.map((cafe) => {
          if (cafe.cafeId === shopId) {
            return {
              ...cafe,
              items: cafe.items.map((item) => {
                // Find the updated item in filteredItems and set its updated price
                const updatedItem = filteredItems
                  .flatMap((itemType) => itemType.itemList)
                  .find((filtered) => filtered.itemId === item.itemId);

                if (updatedItem) {
                  // Update the price in the local storage item
                  return {
                    ...item,
                    price: updatedItem.promoPrice ? updatedItem.promoPrice : updatedItem.price,
                    availability: updatedItem.availability
                  };
                }

                // If no updated item found, return the original item
                return item;
              }),
            };
          }
          return cafe;
        });

        const newLocalStoragee = newLocalStorage.map((cafe) => {
          if (cafe.cafeId === shopId) {
            return {
              ...cafe,
              items: cafe.items.filter((item) =>
                filteredItems.some((filtered) =>
                  filtered.itemList.some(
                    (i) => i.itemId === item.itemId && i.availability
                  )
                )
              ),
            };
          }
          return cafe;
        });
        localStorage.setItem("cart", JSON.stringify(newLocalStoragee));

        window.dispatchEvent(new Event("localStorageUpdated"));

        // Calculate total price based on filtered cart items
        const totalPrice = filteredItems.reduce((total, itemType) => {
          return (
            total +
            itemType.itemList.reduce((subtotal, item) => {
              return subtotal + item.qty * (item.promoPrice ? item.promoPrice : item.price);
            }, 0)
          );
        }, 0);
        setTotalPrice(totalPrice);
      } catch (error) {
        console.error("Error fetching cart items:", error);
        // Handle error if needed
      }

    };

    getNewestCartItems();
  }, [shopId]);

  const handlePayCloseBill = async (orderMethod) =>{
    setIsPaymentLoading(true);
    console.log("tipe" + deviceType);
    if (transactionData) {
      const socketId = socket.id;
      const pay = await handleCloseBillFromGuestDevice(
        transactionData.transactionId,
        orderMethod,
        socketId
      );
    }
  }

  const handlePay = async (orderMethod) => {
    setIsPaymentLoading(true);
    console.log("tipe" + deviceType);
    if (transactionData) {
      const socketId = socket.id;
      const pay = await handleExtendFromGuestDevice(
        shopId,
        transactionData.transactionId,
        textareaRef.current.value,
        socketId
      );
    }
    else

      if (deviceType == "clerk") {
        const pay = await handlePaymentFromClerk(
          shopId,
          email,
          orderMethod,
          orderType,
          tableNumber
        );
      } else if (deviceType == "guestSide") {
        const pay = await handlePaymentFromGuestSide(
          shopId,
          email,
          orderMethod,
          orderType,
          tableNumber
        );
      } else if (deviceType == "guestDevice") {
        const socketId = socket.id;
        const pay = await handlePaymentFromGuestDevice(
          shopId,
          orderMethod,
          orderType,
          table.tableNo || tableNumber,
          textareaRef.current.value,
          socketId
        );
      }

    console.log("transaction from " + deviceType + "success");
    setIsPaymentLoading(false);
  };

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      const handleResize = () => {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      handleResize(); // Initial resize

      textarea.addEventListener("input", handleResize);
      return () => textarea.removeEventListener("input", handleResize);
    }
  }, [textareaRef.current]);

  useEffect(() => {
    if (table?.tableId != undefined) setOrderType("serve");
    console.log(table);
  }, [table]);

  useEffect(() => {
    console.log(localStorage.getItem('cart'))
    console.log(cartItems)

    if (localStorage.getItem('cart') == null || localStorage.getItem('cart') == '' || localStorage.getItem('cart') == '[]') return;

    // Parse the local storage cart
    const localStorageCart = JSON.parse(localStorage.getItem('cart'));
    console.log(localStorageCart)
    // Create a set of itemIds from the local storage cart for quick lookup
    const localStorageItemIds = new Set(localStorageCart[0].items.map(item => item.itemId));

    // Filter out items from cartItems that do not exist in the local storage cart
    const updatedCartItems = cartItems.map(itemType => ({
      ...itemType,
      itemList: itemType.itemList.filter(item => localStorageItemIds.has(item.itemId))
    }));

    setCartItems(updatedCartItems);


    const totalPrice = updatedCartItems.reduce((total, itemType) => {
      return (
        total +
        itemType.itemList.reduce((subtotal, item) => {
          return subtotal + item.qty * item.price;
        }, 0)
      );
    }, 0);
    setTotalPrice(totalPrice);
  }, [localStorage.getItem('cart')]);


  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  const handleOrderMethodChange = (event) => {
    setOrderMethod(event.target.value);
  };

  const handleTableNumberChange = (event) => {
    setTableNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const transactionData = JSON.parse(localStorage.getItem('lastTransaction'));

  return (
    <div className={styles.Invoice} style={{ height: (getItemsByCafeId(shopId).length > 0 ? '' : '100vh'), minHeight: (getItemsByCafeId(shopId).length > 0 ? '100vh' : '') }}>

      <div onClick={goToShop} style={{ marginLeft: '22px', marginTop: '49px', marginRight: '10px', display: 'flex', flexWrap: 'nowrap', alignItems: 'center', fontSize: '25px' }} ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512"><path d="M48,256c0,114.87,93.13,208,208,208s208-93.13,208-208S370.87,48,256,48,48,141.13,48,256Zm212.65-91.36a16,16,0,0,1,.09,22.63L208.42,240H342a16,16,0,0,1,0,32H208.42l52.32,52.73A16,16,0,1,1,238,347.27l-79.39-80a16,16,0,0,1,0-22.54l79.39-80A16,16,0,0,1,260.65,164.64Z" /></svg>Keranjang</div>

      {(transactionData == null && getItemsByCafeId(shopId).length < 1) ?
        <div style={{ height: '75vh', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '50%' }}>
            <svg
              viewBox="0 0 32 32"
              style={{ fill: "#8F8787" }}
            >
              <path d="M9.79175 24.75C8.09591 24.75 6.72383 26.1375 6.72383 27.8333C6.72383 29.5292 8.09591 30.9167 9.79175 30.9167C11.4876 30.9167 12.8751 29.5292 12.8751 27.8333C12.8751 26.1375 11.4876 24.75 9.79175 24.75ZM0.541748 0.0833435V3.16668H3.62508L9.17508 14.8679L7.09383 18.645C6.84717 19.0767 6.70842 19.5854 6.70842 20.125C6.70842 21.8208 8.09591 23.2083 9.79175 23.2083H28.2917V20.125H10.4392C10.2234 20.125 10.0538 19.9554 10.0538 19.7396L10.1001 19.5546L11.4876 17.0417H22.973C24.1292 17.0417 25.1467 16.4096 25.6709 15.4538L31.1901 5.44834C31.3134 5.23251 31.3751 4.97043 31.3751 4.70834C31.3751 3.86043 30.6813 3.16668 29.8334 3.16668H7.03217L5.583 0.0833435H0.541748ZM25.2084 24.75C23.5126 24.75 22.1405 26.1375 22.1405 27.8333C22.1405 29.5292 23.5126 30.9167 25.2084 30.9167C26.9042 30.9167 28.2917 29.5292 28.2917 27.8333C28.2917 26.1375 26.9042 24.75 25.2084 24.75Z" />
            </svg>
          </div>
          <h1 style={{ fontSize: '120%', color: '#8F8787' }}>Tidak ada item di keranjang</h1>
        </div>
        :
        (isLoading ? <></> :
          <>
            {getItemsByCafeId(shopId).length > 0 &&
              <div className={styles.RoundedRectangle}>
                {cartItems.map((itemType) => (
                  <ItemLister
                    shopId={shopId}
                    forInvoice={true}
                    key={itemType.id}
                    typeName={itemType.typeName}
                    itemList={itemType.itemList}
                  />
                ))}

                {table.tableNo != null && (
                  <div className={styles.OrderTypeContainer}>
                    <span htmlFor="orderType">Diantar ke {table.tableNo}</span>
                    {/* <select
            id="orderType"
            value={orderType}
            onChange={handleOrderTypeChange}
          >
            {table != null && (
              <option value="serve">Serve to table {table.tableNo}</option>
            )}
            <option value="pickup">Pickup</option>
            {table == null && <option value="serve">Serve</option>}
          </select> */}
                  </div>
                )}

                {orderType === "serve" && table.length < 1 && (
                  <div className={styles.OrderTypeContainer}>
                    <span htmlFor="orderType">Serve to:</span>
                    <input
                      type="text"
                      placeholder="Table Number"
                      value={tableNumber}
                      onChange={handleTableNumberChange}
                      className={styles.TableNumberInput}
                    />
                  </div>
                )}

                <div className={styles.NoteContainer}>
                  <span>Catatan :</span>
                  <span></span>
                </div>

                <div className={styles.NoteContainer}>
                  <textarea
                    ref={textareaRef}
                    className={styles.NoteInput}
                    placeholder="Tambahkan catatan..."
                  />
                </div>
              </div>
            }

            {transactionData &&
              <div className={styles.RoundedRectangle} style={{ backgroundColor: '#c3c3c3', fontSize: '15px', display: 'flex', justifyContent: 'space-between' }}>
                {transactionData.payment_type != 'paylater' ?
                  <>
                    <div onClick={() => setModal('transaction_item', { transactionId: transactionData.transactionId })} className={styles.AddedLastTransaction}>
                      Pesanan akan ditambahkan ke transaksi sebelumnya
                    </div>
                    <div className={styles.CancelAddedLastTransaction} onClick={() => { window.location.reload(); localStorage.removeItem('lastTransaction') }}>
                      <svg
                        style={{ width: '40px', height: '40px' }}
                        className={styles['plusNegative2']}
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                  </>
                  :

                  <div className={styles.AddedLastTransaction}>
                    <div>
                      Open bill
                      <div onClick={() => setModal('transaction_item', { transactionId: transactionData.transactionId })}>
                        Lihat tagihan
                      </div>
                    </div>
                    {getItemsByCafeId(shopId).length > 0 ?

                      <button className={styles.PayButton3} onClick={() => handlePay(orderMethod)}>
                        {isPaymentLoading ? (
                          <ColorRing height="50" width="50" color="white" />
                        ) : (
                          <div>
                            {transactionData ?

                              <span>Tambahkan</span>
                              :
                              <span>Pesan</span>
                            }

                            <span>Rp{totalPrice}</span>
                          </div>
                        )}
                      </button>
                      :

                      <button className={styles.PayButton3} style={{ backgroundColor: 'rgb(42 145 24)', letterSpacing: '1px' }} onClick={goToShop}>
                        <div>
                          <span>Tambahkan item lain</span>
                        </div>
                      </button>}
                  </div>
                }
              </div>
            }
            <div className={styles.PaymentOption}>
              <div className={styles.TotalContainer}>
                <span>Pembayaran</span>
                <span>
                  {paymentMethods != null && <Dropdown setDropdownKey={() => setDropdownKey(dropdownKey + 1)} paymentMethods={paymentMethods} onChange={handleOrderMethodChange} />}
                </span>
              </div>
              {transactionData && transactionData.payment_type === 'paylater' ?
                <div style={{ display: 'flex', paddingLeft: '25px', paddingRight: '25px', marginTop: '17px' }}>
                  <button className={styles.PayButton} onClick={() => handlePayCloseBill(orderMethod)}>
                    {isPaymentLoading ? (
                      <ColorRing height="50" width="50" color="white" />
                    ) : (
                      <div>
                        <span>Tutup bill</span>

                        <span>Rp{
                          transactionData.DetailedTransactions.reduce((total, transaction) => {
                            return total + (transaction.promoPrice == 0 || transaction.promoPrice == null
                              ? transaction.price * transaction.qty
                              : transaction.promoPrice * transaction.qty);
                          }, 0)
                        }</span>

                      </div>
                    )}
                  </button>
                </div>
                :

                <div style={{ display: 'flex', paddingLeft: '25px', paddingRight: '25px', marginTop: '17px' }}>
                  <button className={styles.PayButton} onClick={() => handlePay(orderMethod)}>
                    {isPaymentLoading ? (
                      <ColorRing height="50" width="50" color="white" />
                    ) : (
                      <div>
                        {transactionData ?

                          <span>Tambahkan</span>
                          :
                          <span>Pesan</span>
                        }

                        <span>Rp{totalPrice}</span>
                      </div>
                    )}
                  </button>
                </div>
              }
            </div>
            <div className={styles.PaymentOptionMargin}></div>
          </>
        )
      }
    </div>
  );
}
