import React from "react";
import styles from "./Transactions.module.css";
import { requestNotificationPermission } from '../services/notificationService'; // Import the notification service

export default function Transaction_pending({ setModal }) {
  // const containerStyle = {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "100%",
  //   height: "100%",
  //   backgroundColor: "",
  // };

  const handleNotificationClick = async () => {
    const permission = await requestNotificationPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Set up notifications or show a success modal
    } else {
      console.error("Notification permission denied.");
      setModal('blocked_notification'); // Show modal for blocked notifications
    }
  };

  return (
    <div className={styles.Transaction}>
        <div style={{ textAlign: "center", padding: '10px' }}>
          
        {/* <img
            className={styles.expression}
            src="https://i.imgur.com/sgvMI02.png"
            alt="Success"
          /> */}
          <h2>Notifikasi tidak aktif</h2>
          <p style={{ marginTop: "20px", color: "black" }}>
          Aktifkan notifikasi supaya kamu tetap dapat info pesanan, meski sedang buka aplikasi lain.
          </p>
          <button
            onClick={handleNotificationClick}
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              backgroundColor: "#4CAF50",
              color: "#fff",
              border: "none",
              borderRadius: "20px",
            }}
          >
            Aktifkan
          </button>
        </div>
    </div>
  );
}
