import React, { useRef, useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import { useParams } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import {
  getTransaction,
  confirmTransaction,
  cancelTransaction,
} from "../helpers/transactionHelpers";
import { getTables } from "../helpers/tableHelper";
import TableCanvas from "../components/TableCanvas";
import { useSearchParams } from "react-router-dom";

export default function Transactions({ propsShopId, sendParam, deviceType, handleMoveToTransaction, shopImg }) {
  const { shopId, tableId } = useParams();
  if (sendParam) sendParam({ shopId, tableId });

  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [transaction, setTransaction] = useState(null);
  const noteRef = useRef(null);

  useEffect(() => {
    const transactionId = searchParams.get("transactionId") || "";

    const fetchData = async () => {
      try {
        const fetchedTransaction = await getTransaction(transactionId);
        setTransaction(fetchedTransaction);
        console.log(fetchedTransaction); // Log the fetched transaction
      } catch (error) {
        console.error("Error fetching transaction:", error);
      }
    };

    const waitForLocalStorage = async () => {
      while (localStorage.getItem("auth") === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second
      }
    };

    const initialize = async () => {
      await waitForLocalStorage();
      await fetchData();
    };

    initialize();
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedTables = await getTables(shopId || propsShopId);
        setTables(fetchedTables);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };

    fetchData();
  }, [shopId || propsShopId]);

  const calculateTotalPrice = (detailedTransactions) => {
    return detailedTransactions.reduce((total, dt) => {
      return total + dt.qty * (dt.promoPrice ? dt.promoPrice : dt.price);
    }, 0);
  };

  const handleConfirm = async (transactionId) => {
    if (isPaymentLoading) return;
    setIsPaymentLoading(true);
    try {
      const c = await confirmTransaction(transactionId);
      if (c) {
        setTransaction({ ...transaction, confirmed: c.confirmed });
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const handleDecline = async (transactionId) => {
    if (isPaymentLoading) return;
    setIsPaymentLoading(true);
    try {
      const c = await cancelTransaction(transactionId);
      // if (c) {
      //   // Update the confirmed status locally
      //   setTransactions((prevTransactions) =>
      //     prevTransactions.map((transaction) =>
      //       transaction.transactionId === transactionId
      //         ? { ...transaction, confirmed: -1 } // Set to confirmed
      //         : transaction
      //     )
      //   );
      // }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const autoResizeTextArea = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set new height
    }
  };

  useEffect(() => {
    if (noteRef.current) {
      autoResizeTextArea(noteRef.current);
    }
  }, [transaction?.notes]);


  return (
    <div className={styles.Transaction}>

      <div className={styles.TransactionListContainer}>
        {transaction && (
          <div
            key={transaction.transactionId}
            className={styles.RoundedRectangle}
            onClick={() =>
              setSelectedTable(transaction.Table || { tableId: 0 })
            }
          >

            <div className={styles['receipt-header']}>
              <ColorRing className={styles['receipt-logo']} />
              <div className={styles['receipt-info']}>
                <h3>memeriksa ketersediaan</h3>
                <p>ID Transaksi: {transaction.transactionId}</p>
                <p>Pembayaran: {transaction.payment_type}</p>
                <p>{transaction.serving_type === "pickup"
                  ? "Ambil sendiri"
                  : `Diantar ke ${transaction.Table ? transaction.Table.tableNo : "N/A"
                  }`}</p>
              </div>
            </div>

            <div className={styles['dotted-line']}>
              <div className={styles['circle-left']} onClick={() => handleMoveToTransaction('previous', transaction.transactionId)}>
              </div>
              <div className={styles['line']} ></div>
              <div className={styles['circle-right']} onClick={() => handleMoveToTransaction('next', transaction.transactionId)}>
              </div>
            </div>
            <div className={styles.RibbonBanner}></div>
            <ul>
              {transaction.DetailedTransactions.map((detail, index) => (
                <>
                {detail.additionalNumber > transaction.DetailedTransactions[index - 1]?.additionalNumber &&

                  <div style={{marginTop: '10px'}} key={detail.detailedTransactionId}>
                    tambah -----
                  </div>
                }
                  <li key={detail.detailedTransactionId}>
                  <span>{detail.Item.name}</span> - {detail.qty < 1 ? 'tidak tersedia' : `${detail.qty} x Rp
                  ${detail.promoPrice ? detail.promoPrice : detail.price}`}
                  </li>
                  </>
              ))}
            </ul>

            <div
              onClick={() => {
                localStorage.setItem('lastTransaction', JSON.stringify(transaction));
              }} className={styles["addNewItem"]}>Tambah pesanan</div>

            <h2 className={styles["Transactions-detail"]}>
              {transaction.serving_type === "pickup"
                ? "Ambil sendiri"
                : `Diantar ke ${transaction.Table ? transaction.Table.tableNo : "N/A"
                }`}
            </h2>
            {transaction.notes != "" && (
              <>
                <div className={styles.NoteContainer}>
                  <span>Note :</span>
                  <span></span>
                </div>

                <div className={styles.NoteContainer}>
                  <textarea
                    className={styles.NoteInput}
                    value={transaction.notes}
                    ref={noteRef}
                    disabled
                  />
                </div>
              </>
            )}
            <div className={styles.TotalContainer}>
              <span>Total:</span>
              <span>
                Rp {calculateTotalPrice(transaction.DetailedTransactions)}
              </span>
            </div>
            {transaction.payment_type != 'paylater' &&
            <div className={styles.TotalContainer}>
              <button
                className={styles.PayButton}
                onClick={() => handleDecline(transaction.transactionId)}
                disabled={
                  transaction.confirmed === -1 ||
                  transaction.confirmed === 3 ||
                  isPaymentLoading
                } // Disable button if confirmed (1) or declined (-1) or
              >
                {isPaymentLoading ? (
                  <ColorRing height="50" width="50" color="white" />
                ) : transaction.confirmed === -1 ? (
                  "Ditolak" // Display "Declined" if the transaction is declined (-1)
                ) : transaction.confirmed === -2 ? (
                  "Dibatalkan" // Display "Declined" if the transaction is declined (-1)
                ) : (
                  "Batalkan" // Display "Confirm availability" if the transaction is not confirmed (0)
                )}
              </button>
            </div>
}
          </div>
        )}
      </div>
    </div>
  );
}
