import React, { useState, useEffect, useRef } from "react";
import styles from "./Modal.module.css";
import { getImageUrl } from "../helpers/itemHelper.js";

const ItemConfig = ({
    name: initialName,
    description: initialDescription,
    price: initialPrice,
    promoPrice: initialPromoPrice,
    imageUrl,
    imageFile,
    isBeingEdit,
    cancelEdit,
    handleCreateItem,
    handleUpdateItem
}) => {
    const [selectedImage, setSelectedImage] = useState();
    const [previewUrl, setPreviewUrl] = useState(imageUrl);
    const [itemName, setItemName] = useState(initialName);
    const [itemPrice, setItemPrice] = useState(initialPrice);
    const [itemPromoPrice, setItemPromoPrice] = useState(initialPromoPrice);
    const [itemDescription, setItemDescription] = useState(initialDescription);
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(() => {

        // Prevent scrolling when modal is open
        document.body.style.overflow = "hidden";
        if(selectedImage){
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(selectedImage);
        }
        else if (imageFile) {
            setPreviewUrl(imageFile);
        } else {
            setPreviewUrl(imageUrl);
        }
    }, [selectedImage, imageFile, imageUrl]);

    const handleOverlayClick = (event) => {
        // Prevent scrolling when modal is open
        document.body.style.overflow = "auto";
        cancelEdit();
    };

    const handleContentClick = (event) => {
        // Prevent click event from propagating to the overlay
        event.stopPropagation();
    };

    const handleChangeImage = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    useEffect(() => {
        const textarea = textareaRef.current;

        if (textarea) {
            const handleResize = () => {
                textarea.style.height = "auto";
                textarea.style.height = `${textarea.scrollHeight}px`;
            };

            handleResize(); // Initial resize

            textarea.addEventListener("input", handleResize);
            return () => textarea.removeEventListener("input", handleResize);
        }
    }, [textareaRef.current]);

    const handleCreate = () => {
        handleCreateItem(itemName, itemPrice, selectedImage, itemDescription, itemPromoPrice);
      };
      const handleUpdate = () => {
        console.log(itemName, itemPrice, selectedImage, itemDescription, itemPromoPrice)
        handleUpdateItem(itemName, itemPrice, selectedImage, itemDescription, itemPromoPrice);
      };

    return (
        <div onClick={handleOverlayClick} style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, bottom: 0, display: 'flex', flexDirection: 'column-reverse', zIndex: 301, backgroundColor: '#00000061' }}>
            <div onClick={handleContentClick} style={{ display: 'flex', flexDirection: 'column', padding: '15px', backgroundColor: 'white', borderRadius: '20px 20px 0 0', overflowY: 'auto' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '26vw', height: '26vw', marginRight: '10px' }}>
                        <img style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }} src={previewUrl} />
                        <input
                            type="file"
                            ref={fileInputRef}
                            className={styles.fileInput}
                            onChange={handleFileChange}
                            accept="image/*"
                            style={{ display: "none" }}
                        />
                    </div>
                    <div style={{ width: '72%', height: '26vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div onClick={() => handleChangeImage()} style={{ width: '140px', height: '40px', alignContent: 'center', textAlign: 'center', borderRadius: '10px', border: '1px solid #60d37e', color: '#60d37e', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {isBeingEdit ? 'Ganti gambar' : 'Tambah gambar'}
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', color: 'black', marginTop: '10px' }}>
                    <p style={{ marginBottom: '5px', fontWeight: '500' }}>Nama item</p>
                    <input
                        value={itemName}
                        style={{
                            padding: '10px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            fontSize: '14px',
                            width: '100%',
                            marginBottom: '15px',
                            outline: 'none',
                            transition: 'all 0.3s ease',
                            boxSizing: 'border-box', // Make sure the padding doesn't cause overflow
                        }}
                        onChange={(e)=>setItemName(e.target.value)}
                        onFocus={(e) => e.target.style.borderColor = '#60d37e'}
                        onBlur={(e) => e.target.style.borderColor = '#ccc'}
                    />
                </div>

                <div style={{ display: 'flex', color: 'black', justifyContent: 'space-between' }}>
                    <div style={{ width: '48%' }}>
                        <p style={{ marginBottom: '5px', fontWeight: '500', marginTop: 0 }}>Harga</p>
                        <input
                            value={itemPrice}
                            style={{
                                padding: '10px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                fontSize: '14px',
                                width: '100%',
                                marginBottom: '15px',
                                outline: 'none',
                                transition: 'all 0.3s ease',
                                boxSizing: 'border-box',
                            }}
                            onChange={(e)=>setItemPrice(e.target.value)}
                            onFocus={(e) => e.target.style.borderColor = '#60d37e'}
                            onBlur={(e) => e.target.style.borderColor = '#ccc'}
                        />
                    </div>
                    <div style={{ width: '48%' }}>
                        <p style={{ marginBottom: '5px', fontWeight: '500', marginTop: 0 }}>Harga promo</p>
                        <input
                            value={itemPromoPrice}
                            placeholder="Opsional"
                            style={{
                                padding: '10px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                fontSize: '14px',
                                width: '100%',
                                marginBottom: '15px',
                                outline: 'none',
                                transition: 'all 0.3s ease',
                                boxSizing: 'border-box',
                            }}
                            onChange={(e)=>setItemPromoPrice(e.target.value)}
                            onFocus={(e) => e.target.style.borderColor = '#60d37e'}
                            onBlur={(e) => e.target.style.borderColor = '#ccc'}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', color: 'black' }}>
                    <p style={{ marginBottom: '5px', fontWeight: '500', marginTop: 0 }}>Deskripsi</p>

                    <textarea
                        ref={textareaRef}
                        style={{
                            padding: '10px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            fontSize: '14px',
                            width: '100%',
                            marginBottom: '15px',
                            outline: 'none',
                            transition: 'all 0.3s ease',
                            boxSizing: 'border-box',
                            resize: 'none', // Prevent manual resize that could cause overflow
                        }}
                        placeholder="Tambah deskripsi..."
                        value={itemDescription}
                        onChange={(e)=>setItemDescription(e.target.value)}
                        onFocus={(e) => e.target.style.borderColor = '#60d37e'}
                        onBlur={(e) => e.target.style.borderColor = '#ccc'}
                    />
                </div>

                <div style={{ width: '100%', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div onClick={() => {isBeingEdit ? handleUpdate() : handleCreate()} } style={{ width: '100%', height: '40px', alignContent: 'center', textAlign: 'center', borderRadius: '10px', border: '1px solid #60d37e', color: '#60d37e', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {isBeingEdit? 'Simpan' : 'Buat Item'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemConfig;
