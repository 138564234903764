import React, { useState } from "react";

import API_BASE_URL from "../config.js";

function getAuthToken() {
    return localStorage.getItem("auth");
  }
  
const CheckCouponPage = () => {
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [couponDetails, setCouponDetails] = useState(null);

  const handleCheckCoupon = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/coupon/check/${couponCode}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCouponStatus("Coupon is valid");
        setCouponDetails(data.coupon);
      } else {
        setCouponStatus("Coupon not found or expired");
        setCouponDetails(null);
      }
    } catch (error) {
      setCouponStatus("Error checking coupon.");
      setCouponDetails(null);
    }
  };

  return (
    <div>
      <h1>Check Coupon</h1>
      <div>
        <label>Coupon Code:</label>
        <input
          type="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
      </div>
      <button onClick={handleCheckCoupon}>Check Coupon</button>
      {couponStatus && <p>{couponStatus}</p>}
      {couponDetails && (
        <div>
          <p>Coupon Code: {couponDetails.code}</p>
          <p>Discount Type: {couponDetails.discountType}</p>
          <p>Discount Value: {couponDetails.discountValue}</p>
          <p>Discount Periods: {couponDetails.discountPeriods} weeks</p>
          <p>Expiration Date: {couponDetails.expirationDate || "No expiration"}</p>
        </div>
      )}
    </div>
  );
};

export default CheckCouponPage;
