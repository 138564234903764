// WelcomePage.js
import React,{useRef} from "react";
import "./WelcomePage.css";

const WelcomePage = ({
  onGetStarted,
  isFullscreen,
  image,
  welcomingText,
  backgroundColor,
  textColor,
  onImageChange
}) => {
  const fileInputRef = useRef(null);
  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div
      className={`welcome-page ${isFullscreen ? "fullscreen" : ""}`} // Corrected the className syntax
      style={{
        backgroundColor,
      }}
    >
      <div
        style={{
          backgroundColor: image ? "transparent" : "black",position:'relative'
        }}
        className="image-container"
      >
        {!isFullscreen &&
        <div onClick={handleImageClick} style={{width: '100%', height: '100%', position:'absolute'}}>
          <h1 style={{textAlign:'left'}}>
            {image ? "Click To Change Image" : "Click To Add Image"}
          </h1>
          <input 
            ref={fileInputRef} style={{display: 'none', width:'100%', height: '100%'}}type="file" accept="image/*" onChange={onImageChange} />
        </div>
        }
        {image && <img  src={image} alt="Welcome" className="circular-image" />} {/* Added conditional rendering */}
      </div>
      <h1 className="welcoming-text" style={{ color: textColor }}>
        {welcomingText}
      </h1>
      <button className="get-started-button" onClick={onGetStarted}>
        Get Started
      </button>
    </div>
  );
};

export default WelcomePage;
