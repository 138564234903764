// helpers/couponHelpers.js

import API_BASE_URL from '../config.js';

// Helper function to get the auth token from localStorage
export function getAuthToken() {
  return localStorage.getItem('auth');
}
// Function to create a coupon
export async function createCoupon(discountType, discountValue, discountPeriods, codeExpectation) {
  try {
    const bodyData = {
      discountType,
      discountValue,
      discountPeriods,
    };

    // Conditionally add `codeExpectation` only if it's defined
    if (codeExpectation !== null && codeExpectation !== undefined) {
      bodyData.couponCodeExpect = codeExpectation;
    }

    const response = await fetch(`${API_BASE_URL}/coupon/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify(bodyData),
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, coupon: data.coupon };
    } else {
      return { success: false, message: 'Failed to create coupon.' };
    }
  } catch (error) {
    console.error('Error creating coupon:', error);
    return { success: false, message: 'Error creating coupon.' };
  }
}

// Function to check the validity of the coupon code
export async function checkCoupon(couponCode) {
  try {
    const response = await fetch(`${API_BASE_URL}/coupon/check/${couponCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { status: 'Coupon is valid', coupon: data.coupon };
    } else {
      return { status: 'Coupon not found or expired', coupon: null };
    }
  } catch (error) {
    console.error('Error checking coupon:', error);
    return { status: 'Error checking coupon.', coupon: null };
  }
}

// Function to create a user with the coupon code
export async function createUserWithCoupon(username, email, password, couponCode) {
  try {
    const response = await fetch(`${API_BASE_URL}/coupon/create-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        email,
        password,
        couponCode,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, token: data.token };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.message || 'Error creating user' };
    }
  } catch (error) {
    console.error('Error creating user with coupon:', error);
    return { success: false, message: 'Error creating user.' };
  }
}

// Function to create a user with the coupon code
export async function logCouponForUser(couponCode) {
  try {
    const response = await fetch(`${API_BASE_URL}/coupon/log-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify({
        couponCode,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.message || 'Error creating user' };
    }
  } catch (error) {
    console.error('Error creating user with coupon:', error);
    return { success: false, message: 'Error creating user.' };
  }
}

// Function to check the validity of the coupon code
export async function getUserCoupons() {
  try {
    const response = await fetch(`${API_BASE_URL}/coupon/get/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { status: 'Coupon is valid', coupons: data.coupons };
    } else {
      return { status: 'Coupon not found or expired', coupon: null };
    }
  } catch (error) {
    console.error('Error checking coupon:', error);
    return { status: 'Error checking coupon.', coupon: null };
  }
}
