import React, { useRef, useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import { useParams } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import ButtonWithReplica from "../components/ButtonWithReplica";
import {
  getTransaction,
  confirmTransaction,
  handleClaimHasPaid,
  declineTransaction,
  cancelTransaction,
} from "../helpers/transactionHelpers";
import { getTables } from "../helpers/tableHelper";
import TableCanvas from "../components/TableCanvas";
import { useSearchParams } from "react-router-dom";

export default function Transactions({
  propsShopId,
  sendParam,
  deviceType,
  paymentUrl,
}) {
  const { shopId, tableId } = useParams();
  if (sendParam) sendParam({ shopId, tableId });

  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [transaction, setTransaction] = useState(null);
  const noteRef = useRef(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  useEffect(() => {
    const transactionId = searchParams.get("transactionId") || "";

    const fetchData = async () => {
      try {
        const fetchedTransaction = await getTransaction(transactionId);
        if (fetchedTransaction.payment_type == 'paylater' && deviceType == 'guestDevice') localStorage.setItem('lastTransaction', JSON.stringify(fetchedTransaction));
        setTransaction(fetchedTransaction);
        console.log(fetchedTransaction); // Log the fetched transaction
      } catch (error) {
        console.error("Error fetching transaction:", error);
      }
    };

    const waitForLocalStorage = async () => {
      while (localStorage.getItem("auth") === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second
      }
    };

    const initialize = async () => {
      await waitForLocalStorage();
      await fetchData();
    };

    initialize();
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedTables = await getTables(shopId || propsShopId);
        setTables(fetchedTables);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };

    fetchData();
  }, [shopId || propsShopId]);

  const calculateTotalPrice = (detailedTransactions) => {
    return detailedTransactions.reduce((total, dt) => {
      return total + dt.qty * (dt.promoPrice ? dt.promoPrice : dt.price);
    }, 0);
  };

  const handleConfirm = async (transactionId) => {
    if (isPaymentLoading) return;
    setIsPaymentLoading(true);
    try {
      const c = await handleClaimHasPaid(transactionId);
      if (c) {
        setTransaction({
          ...transaction,
          confirmed: c.confirmed,
          paymentClaimed: true,
        });
        console.log(transaction);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const handleDecline = async (transactionId) => {
    if (isPaymentLoading) return;
    setIsPaymentLoading(true);
    try {
      const c = await cancelTransaction(transactionId);
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsPaymentLoading(false);
    }
  };

  const autoResizeTextArea = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set new height
    }
  };

  useEffect(() => {
    if (noteRef.current) {
      autoResizeTextArea(noteRef.current);
    }
  }, [transaction?.notes]);

  return (
    <div className={styles.Transaction}>
      <div className={styles.TransactionListContainer} style={{ overflow: 'hidden' }}>
        {transaction && (
          <div
            key={transaction.transactionId}
            className={styles.RoundedRectangle}
            onClick={() =>
              setSelectedTable(transaction.Table || { tableId: 0 })
            }
          >

            <div className={styles['receipt-header']}>
                <div>
                  <svg
                    height="60px"
                    width="60px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 506.4 506.4"
                    xmlSpace="preserve"
                    fill="#000000"
                    style={{marginTop: '12px', marginBottom: '12px'}}
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <circle style={{ fill: '#54B265' }} cx="253.2" cy="253.2" r="249.2" />
                      <path
                        style={{ fill: '#F4EFEF' }}
                        d="M372.8,200.4l-11.2-11.2c-4.4-4.4-12-4.4-16.4,0L232,302.4l-69.6-69.6c-4.4-4.4-12-4.4-16.4,0 L134.4,244c-4.4,4.4-4.4,12,0,16.4l89.2,89.2c4.4,4.4,12,4.4,16.4,0l0,0l0,0l10.4-10.4l0.8-0.8l121.6-121.6 C377.2,212.4,377.2,205.2,372.8,200.4z"
                      ></path>
                      <path d="M253.2,506.4C113.6,506.4,0,392.8,0,253.2S113.6,0,253.2,0s253.2,113.6,253.2,253.2S392.8,506.4,253.2,506.4z M253.2,8 C118,8,8,118,8,253.2s110,245.2,245.2,245.2s245.2-110,245.2-245.2S388.4,8,253.2,8z"></path>
                      <path d="M231.6,357.2c-4,0-8-1.6-11.2-4.4l-89.2-89.2c-6-6-6-16,0-22l11.6-11.6c6-6,16.4-6,22,0l66.8,66.8L342,186.4 c2.8-2.8,6.8-4.4,11.2-4.4c4,0,8,1.6,11.2,4.4l11.2,11.2l0,0c6,6,6,16,0,22L242.8,352.4C239.6,355.6,235.6,357.2,231.6,357.2z M154,233.6c-2,0-4,0.8-5.6,2.4l-11.6,11.6c-2.8,2.8-2.8,8,0,10.8l89.2,89.2c2.8,2.8,8,2.8,10.8,0l132.8-132.8c2.8-2.8,2.8-8,0-10.8 l-11.2-11.2c-2.8-2.8-8-2.8-10.8,0L234.4,306c-1.6,1.6-4,1.6-5.6,0l-69.6-69.6C158,234.4,156,233.6,154,233.6z"></path>
                    </g>
                  </svg>
                </div>

              <div className={styles['receipt-info']}>
                <h3>Transaksi Berhasil</h3>
                <p>Transaction ID: {transaction.transactionId}</p>
                <p>Payment Type: {transaction.payment_type}</p>
              </div>
            </div>

            <ul>
              {(isPaymentOpen
                ? transaction.DetailedTransactions.slice(0, 2)
                : transaction.DetailedTransactions
              ).map((detail) => (
                <li key={detail.detailedTransactionId}>
                  <span>{detail.Item.name}</span> - {detail.qty < 1 ? 'tidak tersedia' : `${detail.qty} x Rp
                  ${detail.promoPrice ? detail.promoPrice : detail.price}`}
                </li>
              ))}
            </ul>

            <h2 className={styles["Transactions-detail"]}>
              {transaction.serving_type === "pickup"
                ? "Ambil sendiri"
                : `Diantar ke ${transaction.Table ? transaction.Table.tableNo : "N/A"
                }`}
            </h2>

            {(transaction.notes !== "") && (
              <>
                <div
                  className={styles.NoteContainer}
                  style={{
                    visibility: transaction.notes == "" ? "hidden" : "visible",
                  }}
                >
                  <span>Note :</span>
                  <span></span>
                </div>

                <div
                  className={styles.NoteContainer}
                  style={{
                    visibility: transaction.notes == "" ? "hidden" : "visible",
                  }}
                >
                  <textarea
                    className={styles.NoteInput}
                    value={transaction.notes}
                    ref={noteRef}
                    disabled
                  />
                </div>
              </>
            )}
            {transaction.payment_type != 'paylater' ?
              <>
                <div className={styles.TotalContainer}>
                  <span>Total:</span>
                  <span>
                    Rp {calculateTotalPrice(transaction.DetailedTransactions)}
                  </span>
                </div>
              </>
              :

              <h5
                className={`${styles.DeclineButton}`}
              >
                Open bill disetujui, silahkan lanjutkan pemesanan anda
              </h5>
            }
          </div>
        )}
      </div>
    </div>
  );
}
