import React, { useState, useRef, useEffect } from "react";
import styles from "./Item.module.css";
const Item = ({
  blank,
  forCart,
  forInvoice,
  name: initialName,
  description: initialDescription,
  price: initialPrice,
  promoPrice,
  qty: initialQty,
  imageUrl,
  imageFile,
  id,
  onPlusClick,
  onNegativeClick,
  handleCreateItem,
  handleUpdateItem,
  onRemoveClick,
  isAvailable,
  isBeingEdit,
  last
}) => {
  const [selectedImage, setSelectedImage] = useState(imageFile);
  const [previewUrl, setPreviewUrl] = useState(imageUrl);
  const [itemQty, setItemQty] = useState(blank ? 0 : initialQty);
  const [itemName, setItemName] = useState(initialName);
  const [itemPrice, setItemPrice] = useState(initialPrice);
  const [itemDescription, setItemDescription] = useState(initialDescription);
  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log(imageUrl);
    console.log(selectedImage);
    console.log(imageFile)
    if (imageFile) {
      setPreviewUrl(imageFile);
    } else {
      setPreviewUrl(imageUrl);
    }
  }, [imageFile, imageUrl]);

  const handlePlusClick = () => {
    if (!blank) onPlusClick(id);
    setItemQty(itemQty + 1);
  };

  const handleNegativeClick = () => {
    if (itemQty > 0) {
      if (!blank) onNegativeClick(id);
      setItemQty(itemQty - 1);
    }
  };

  const handleCreate = () => {
    handleCreateItem(itemName, itemPrice, selectedImage, previewUrl);
  };
  const handleUpdate = () => {
    handleUpdateItem(itemName, itemPrice, selectedImage, previewUrl);
  };

  const handleRemoveClick = () => {
    onRemoveClick(id);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handlePriceChange = (event) => {
    setItemPrice(event.target.value);
  };

  const handleQtyChange = (event) => {
    const newQty = parseInt(event.target.value, 10);
    if (!isNaN(newQty)) {
      setItemQty(newQty);
    }
  };

  const handleNameChange = (event) => {
    setItemName(event.target.value);
  };

  return (
    <div className={`${!last && !forInvoice ? styles.notLast : ""}`}>
      <div className={`${styles.item} ${forInvoice ? styles.itemInvoice : ""} `}>
        {!forInvoice && (
          // <div className={styles.imageContainer}>
          <img
            src={
              previewUrl
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://png.pngtree.com/png-vector/20221125/ourmid/pngtree-no-image-available-icon-flatvector-illustration-pic-design-profile-vector-png-image_40966566.jpg";
            }}
            alt={itemName}
            style={{
              filter: !isAvailable ? "grayscale(100%)" : "none",
            }}
            className={styles.imageContainer}
          />
          // </div>
        )}
        <div className={styles.itemDetails}>
          {forInvoice &&
            <svg
              className={styles.plusNegative2}
              onClick={onRemoveClick}
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                fillRule="nonzero"
              />
            </svg>

          }
          {/* <input
          className={`${
            forInvoice ? styles.itemInvoiceName : styles.itemName
          } ${isBeingEdit || blank ? styles.blank : styles.notblank} ${
            !isAvailable ? styles.disabled : ""
          }`}
          value={itemName}
          placeholder="Nama item"
          onChange={handleNameChange}
          disabled={!blank && !isBeingEdit}
        /> */}
          <h3 style={{
            textTransform: 'capitalize',
            margin: `${forInvoice ? '13px 0px 10px 10px' : '5px 0px 10px 10px'}`,
            fontSize: '16px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            textOverflow: 'ellipsis',
            width: `${forInvoice? '160px' : 'unset'}`
          }}>
            {itemName}
          </h3>
          {forInvoice && (
            <>
              <p className={styles.multiplySymbol}>x</p>
              <p className={styles.qtyInvoice}>{itemQty}</p>
            </>
          )}
          {!forInvoice && (
            // <input
            //   className={`${styles.itemPrice} ${
            //     isBeingEdit || blank ? styles.blank : styles.notblank
            //   } ${!isAvailable ? styles.disabled : ""}`}
            //   value={itemPrice}
            //   placeholder="Harga"
            //   onChange={handlePriceChange}
            //   disabled={!blank && !isBeingEdit}
            // />

            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              color: 'rgb(28, 29, 29)',
              fontSize: '0.875rem',
              fontWeight: 600,
              lineHeight: '1rem',
              marginLeft: 10
            }}>
              {promoPrice && promoPrice != 0 && promoPrice != '' ?
                <>
                  <div style={{
                    position: 'relative',
                    marginTop: '0.125rem',
                    display: 'flex',
                    width: '87px',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    borderRadius: '9999px',
                    backgroundColor: !isAvailable ? 'gray' : 'unset',
                    backgroundImage: isAvailable && 'linear-gradient(to right, #e52535, #eb525e)',
                    padding: '0.25rem 0rem',
                    color: 'rgb(255, 255, 255)',
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    lineHeight: '1rem',
                    justifyContent: 'center'
                  }}>
                    Promo {(((initialPrice - promoPrice) / initialPrice) * 100).toFixed(0)}%
                  </div>

                  <div style={{ display: 'flex' }}>
                    <span style={{
                      marginLeft: '1rem',
                      marginRight: '0.5rem',
                      marginTop: '0.125rem'
                    }}>{promoPrice}</span>
                    <span style={{
                      marginTop: '0.125rem',
                      color: 'rgb(114, 114, 114)',
                      textDecoration: 'line-through'
                    }}>{initialPrice}</span>
                  </div>
                </>
                :
                <>

                  <div style={{ display: 'flex' }}>
                    <span style={{
                      marginRight: '0.5rem',
                      marginTop: '0.125rem'
                    }}>{initialPrice}</span>
                  </div>
                </>
              }
            </div>
          )}

          {!forInvoice &&
            (!isBeingEdit && itemQty != 0 ? (
              <div className={styles.itemQty}>
                <svg
                  className={styles.plusNegative}
                  onClick={handleNegativeClick}
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm4.253 7.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"
                    fillRule="nonzero"
                  />
                </svg>
                {!blank && !isBeingEdit ? (
                  <p className={styles.itemQtyValue}>{itemQty}</p>
                ) : (
                  <input
                    className={styles.itemQtyInput}
                    value={itemQty}
                    onChange={handleQtyChange}
                    disabled={!blank && !isBeingEdit}
                  />
                )}
                <svg
                  className={styles.plusNegative}
                  onClick={handlePlusClick}
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
                    fillRule="nonzero"
                  />
                </svg>
              </div>
            ) : !blank && !isBeingEdit ? (
              <div className={styles.itemQty}>
                <button
                  className={styles.addButton}
                  style={{ backgroundColor: !isAvailable ? "" : "inherit", border: `2px solid ${isAvailable ? 'inherit' : 'gray'}`, color: `${isAvailable ? '#a8c7a9' : 'gray'}` }}
                  onClick={handlePlusClick}
                  disabled={!isAvailable} // Optionally disable the button if not available
                >
                  Pesan
                </button>
              </div>
            ) : (
              <div className={styles.itemQty}>
                <button
                  className={styles.addButton}
                  style={{
                    backgroundColor: "white",
                    width: "150px",
                    color: '#a8c7a9'
                  }}
                  onClick={isBeingEdit ? handleUpdate : handleCreate}
                >
                  {isBeingEdit ? "Simpan" : "Buat"}
                </button>
              </div>
            ))}

          {forInvoice && (
            <p className={styles.itemPriceInvoice}>Rp {itemQty * (promoPrice > 0? promoPrice : itemPrice)}</p>
          )}
        </div>
        {forCart && (
          <div className={styles.remove} onClick={handleRemoveClick}>
            ⓧ
          </div>
        )}
        {/* {blank && (
        <button className={styles.createItem} onClick={handleCreate}>
          Create Item
        </button>
      )} */}
      </div>
      {itemDescription && itemDescription != 'undefined' && itemDescription?.length &&
        <div>
          <p style={{
            maxHeight: '34px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            textOverflow: 'ellipsis', color: '#5f5f5f', fontSize: '14px', padding: '5px', margin: 0
          }}>{itemDescription}</p>
        </div>
      }
    </div>
  );
};

export default Item;
